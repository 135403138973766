import Vue from "vue";
import { createFooterStore } from "./store";

import Footer from "./Footer.vue";

if (process.env.NODE_ENV !== "production") {
  Vue.config.devtools = true;
}

export function createApp() {
  const store = createFooterStore();

  // sync(store);

  const app = new Vue({
    store,
    render: (h) => h(Footer),
  });

  //   return { app, router };
  return { app, store };
}
