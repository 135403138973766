<template>
  <ul
    data-style="social-icons"
    class="social-icons-list"
    data-component="Global-footer-SocialIcons"
  >
    <li
      v-for="(icon, index) in icons_list"
      :key="index"
      :class="icon.network.toLowerCase()"
    >
      <a :href="icon.link" target="_new">
        <component :is="icon.network" :fill="icon_color"></component>
      </a>
    </li>
  </ul>
</template>

<script>
import { getConfigById } from "../../util/context";
import Facebook from "../social_icons/Facebook.vue";
import Twitter from "../social_icons/Twitter.vue";
import Instagram from "../social_icons/Instagram.vue";
import Youtube from "../social_icons/Youtube.vue";
import Youtube2 from "../social_icons/Youtube2.vue";
import Pinterest from "../social_icons/Pinterest.vue";
import Blog from "../social_icons/Blog.vue";
import { logger } from "@js-ecom-mfe/logger";
import { REPONAME } from "../../util/constants";

const fileName = "SocialIcons.vue";
export default {
  name: "socialIcons",
  components: {
    Facebook,
    Twitter,
    Instagram,
    Youtube,
    Youtube2,
    Pinterest,
    Blog,
  },
  methods: {
    getIconsList() {
      const social_icons_list = getConfigById("socialIcons", this);
      if (!social_icons_list || !social_icons_list.icons_list) {
        logger.warn(
          REPONAME,
          fileName,
          "getIconsList",
          `Config error in socialIcons`
        );
        return [];
      }
      return social_icons_list.icons_list;
    },
    getIconColor() {
      const social_icons_list = getConfigById("socialIcons", this);
      if (!social_icons_list || !social_icons_list.icon_color) {
        logger.warn(
          REPONAME,
          fileName,
          "getIconColor",
          `Config error in socialIcons`
        );
        return [];
      }
      return social_icons_list.icon_color;
    },
  },
  created() {
    this.icons_list = this.getIconsList();
    this.icon_color = this.getIconColor();
  },
};
</script>
