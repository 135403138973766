/**
 * Gets invoked on clicking feedback link and launches online opinion
 */

import { getConfigById } from "../util/context";

export default {
  methods: {
    launchOO(event) {
      window.OOo.oo_launch(
        event,
        "oo_inline_menu",
        getConfigById("longConceptName", this)
      );
    },
  },
};
