import {
  ECM_OVERLAY_CONTENT_TAG,
  ECM_OVERLAY_TRIGGER_CLASS,
} from "./constants";
import { getEventPath } from "./domEvents";

/**
 * Parses the node tree from the MouseEvent from when a user clicks on an ECM Promo, and returns
 * the Overlay Trigger if it exists in the node tree.
 * @param {Object} promoClickEvent MouseEvent when user clicks on an ECM promo
 * @returns {Object} HTMLElement for an ECM Overlay Trigger if it exists
 */
export const getOverlayTrigger = (promoClickEvent) => {
  if (
    !promoClickEvent ||
    !(
      promoClickEvent.path ||
      promoClickEvent.composedPath ||
      promoClickEvent.target
    )
  ) {
    // Invalid mouse event: must contain a path, composedPath, or target property in order to build
    // a path node tree to see if an overlayTrigger class exists.
    return null;
  } else {
    return (
      getEventPath(promoClickEvent).find(
        (el) =>
          el.classList && [...el.classList].includes(ECM_OVERLAY_TRIGGER_CLASS)
      ) || null
    );
  }
};

/**
 * Parses the HTTP response from an ECM Overlay page, and returns the body content from the
 * response (e.g. find the first instance of `<script type="text/html" id="overlayTemplate_ecmOverlay">`,
 * then pull the HTML content from inside the tag).
 * @param {String} responseString Response from async GET
 * @returns {String} ECM Overlay content as a string
 */
export const extractOverlayContent = (responseString) => {
  if (
    !responseString ||
    typeof responseString !== "string" ||
    responseString.indexOf(ECM_OVERLAY_CONTENT_TAG) < 0
  ) {
    return null;
  }
  const ecmBodySplit = responseString.split(ECM_OVERLAY_CONTENT_TAG);
  if (ecmBodySplit.length < 2 || ecmBodySplit[1].indexOf("</script>") < 0) {
    return null;
  }
  const ecmBodyContent = ecmBodySplit[1];
  const scriptTagCloseIndex = ecmBodyContent.indexOf("</script>");
  return ecmBodyContent.substring(0, scriptTagCloseIndex);
};
