<template>
  <div data-style="blog-link" class="blog-container" v-if="blogData.link">
    <a class="blog-link" :href="blogData.link" target="_blank">
      <SVGIcon :icon="blogData.icon"></SVGIcon>
    </a>
  </div>
</template>

<script>
const SVGIcon = () =>
  import(/* webpackChunkName: "header-footer-svg-icon" */ "../SVGIcon.vue");

import { getConfigById } from "../../util/context";

export default {
  name: "BlogLink",
  components: {
    SVGIcon,
  },
  data() {
    return {
      blogData: {},
    };
  },
  created() {
    this.blogData = getConfigById("footerLinks", this).blog_data;
  },
};
</script>
