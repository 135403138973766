import {
  fetchBingAddressFromLatLng,
  loadBingMapLib,
} from "../util/bing-maps.js";
import { loadGoogleMapLib, fetchAddressFromLatLng } from "../util/google-maps";
import { DEFAULT_POSTAL_CODE } from "./constants";
/**
 * Determines if stored postal code in WSI state should be updated, only updates if postal code is not 6 character
 * Only executes based on the config - enablePostalCodeOverrideFromLatLng
 *
 * @param {String} postalCode - Postal code
 * @param {Object} myStoreConfig - Display Location configs
 *
 * @returns {Boolean} - Returns true if the postal code is invalid format and needs to be updated from maps API
 */
export function isPostalCodeUpdateRequired(postalCode, myStoreConfig) {
  const enablePostalCodeOverrideFromLatLng =
    myStoreConfig?.enablePostalCodeOverrideFromLatLng ?? false;
  const postalCodeFormat = /^([A-Za-z]\d[A-Za-z]\s{0,1}?\d[A-Za-z]\d)$/; // Regex for A1A 1A1 and A1A1A1 format
  const isValidPostalCode =
    postalCode && postalCodeFormat.test(postalCode.trim());

  return enablePostalCodeOverrideFromLatLng && !isValidPostalCode;
}

/**
 * Gets the postal code from the configured API in configs for the provided lat and lng
 *
 * @param {String} currentPostalCode -- Invalid postal code thats needs to be updated
 * @param {String} lat -- Latitude for the postal code
 * @param {String} lng -- Longitude for the postal code
 * @param {Object} configs -- configs to be used for maps API
 *
 * @default {String} - DEFAULT_POSTAL_CODE
 * @returns {String} - Returns new postal code from Bing/Google API, if not found defaults to postal code
 */
export async function getValidPostalCode(lat, lng, configs) {
  const newAddressDetails = await getAddressFromLatLng(
    {
      lat: lat,
      lng: lng,
    },
    configs
  );
  if (newAddressDetails.zipCode) {
    return newAddressDetails.zipCode;
  } else {
    // If API fails return the default Postal code
    return DEFAULT_POSTAL_CODE;
  }
}

/**
 * Loads the Google/Bing Maps API if not loaded. Returns and address object based on lat & lng
 *
 * @param {Object} latlng -- An object containing lat & lng properties
 *
 * @returns {Promise} -- On success the resolve will be called with the coordinates, on failure the reject will be called with the error
 */
async function getAddressFromLatLng(latlng, configs) {
  //maps.provider could either be "Google" or "Bing" - from JSON configs
  if (configs.maps) {
    const maps = configs.maps;
    window.mapsProvider = maps.provider;
  }
  if (window.mapsProvider === "Bing") {
    const bingMaps = configs.bingMaps;
    loadBingMapLib(bingMaps);
    const cords = await fetchBingAddressFromLatLng(latlng);
    return cords;
  } else {
    const googleMaps = configs.googleMaps;
    loadGoogleMapLib(googleMaps);
    const cords = await fetchAddressFromLatLng(latlng);
    return cords;
  }
}
