import axios from "axios";
import { getConfigById } from "../util/context.js";
import { extractOverlayContent } from "../util/ecmPromo";
import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";
import { GENERIC_SERVICE_TIME_OUT } from "./const/timeouts";

const fileName = "ecm.js";

const SERVICE_URL = `category/content/`;
const QUERY_PARAM_TYPE = `type=`;
const QUERY_PARAM_SUBTYPE = `subType=`;
const TYPE_PROMO = `promos`;
const SUBTYPE_SPOTLIGHT = `spotlight`;

function getCatIdFromPath(path) {
  return path.split("/").slice(-1)[0];
}

/**
 * Gets all Desktop ECM Content with type `promo` for global header, footer, nav, including:
 * - global-wide-banner-top
 * - global-wide-banner
 * - spotlights
 * @param {string} applicationUri -- Host URL
 * @param {object} paths list of (supercat) paths to pull promos for
 */
export const getAllPromosForDesktop = (
  applicationUri,
  paths,
  scope,
  ecmTimeParam,
  currentPath
) => {
  const ecmTimeParamQuery = ecmTimeParam ? `${ecmTimeParam}&` : ``;

  const ecmPath = getEcmPath(currentPath, scope);

  const serviceURL = `${applicationUri}${SERVICE_URL}${ecmPath}?${ecmTimeParamQuery}${QUERY_PARAM_TYPE}${TYPE_PROMO}`;
  const SUBTYPE_LIST_DESKTOP = getConfigById(
    "ecmPlacements",
    scope
  ).DesktopPlacements;

  // Build subType Query Param:
  const subTypes = paths
    .reduce((acc, path) => {
      const catId = getCatIdFromPath(path);
      // Spotlight Query Param
      acc.push(`${SUBTYPE_SPOTLIGHT}-${catId}`);
      return acc;
    }, [])
    .join(",");
  // eslint-disable-next-line no-console
  logger.debug(
    REPONAME,
    fileName,
    "getAllPromosForDesktop",
    `ECM Desktop service call: ${serviceURL}&${QUERY_PARAM_SUBTYPE}${subTypes},${SUBTYPE_LIST_DESKTOP.join(
      ","
    )}`
  );

  try {
    const options = { timeout: GENERIC_SERVICE_TIME_OUT };
    const response = axios.get(
      `${serviceURL}&${QUERY_PARAM_SUBTYPE}${subTypes},${SUBTYPE_LIST_DESKTOP.join(
        ","
      )}`,
      options
    );
    return response;
  } catch (error) {
    logger.error(REPONAME, fileName, "getAllPromosForDesktop", error);
    return Promise.reject(error);
  }
};

/**
 * Gets all Mobile ECM Content with type `promo` for global header, footer, nav, including:
 * - global-wide-banner-top
 * - global-wide-banner
 * - spotlights
 * @param {string} applicationUri -- Host URL
 * @param {object} paths list of (supercat) paths to pull promos for
 */
export const getAllPromosForMobile = (applicationUri, scope, currentPath) => {
  const ecmPath = getEcmPath(currentPath, scope);
  const serviceURL = `${applicationUri}${SERVICE_URL}m/${ecmPath}?${QUERY_PARAM_TYPE}${TYPE_PROMO}`;
  const SUBTYPE_LIST_MOBILE = getConfigById(
    "ecmPlacements",
    scope
  ).MobilePlacements;

  logger.debug(
    REPONAME,
    fileName,
    "getAllPromosForMobile",
    `ECM Mobile service call: ${serviceURL}&${QUERY_PARAM_SUBTYPE}${SUBTYPE_LIST_MOBILE.join(
      ","
    )}`
  );
  try {
    const apiOptions = { timeout: GENERIC_SERVICE_TIME_OUT };
    const response = axios.get(
      `${serviceURL}&${QUERY_PARAM_SUBTYPE}${SUBTYPE_LIST_MOBILE.join(",")}`,
      apiOptions
    );
    return response;
  } catch (error) {
    logger.error(REPONAME, fileName, "getAllPromosForMobile", error);
    return Promise.reject(error);
  }
};

/**
 * Fetches the ECM Overlay (body) content for given overlayContentUrl.
 * Returns the body content in a string format so it can be rendered through v-html.
 * @param {String} overlayContentUrl Url to fetch the overlay content from
 * @returns {String} ECM overlay content
 */
export const getOverlayContent = async (overlayContentUrl) => {
  const methodName = "getOverlayContent";
  try {
    const response = await axios.get(overlayContentUrl, {
      timeout: GENERIC_SERVICE_TIME_OUT,
    });
    return extractOverlayContent(response.data);
  } catch (error) {
    logger.error(REPONAME, fileName, methodName, error);
    return Promise.reject(error);
  }
};

const getEcmPath = (currentPath, scope) => {
  const { isPathSpecificPromo = false } = getConfigById("ecmPlacements", scope);
  let ecmPath = "shop/";
  if (isPathSpecificPromo && currentPath.includes("shop")) {
    ecmPath = currentPath.includes("?")
      ? currentPath
          .substring(0, currentPath.indexOf("?"))
          .substring(currentPath.indexOf("shop"))
      : currentPath.substring(currentPath.indexOf("shop"));
  }
  return ecmPath;
};

export const fetchEcmContentData = async ({
  applicationUri,
  isMobile,
  contentIds = [],
  route = "/",
}) => {
  try {
    if (!contentIds.length) {
      return;
    }
    const locationsQueryParams = contentIds
      .map((location) => `${location}${removeEndingSlashes(route)}`)
      .join("&locations=");

    const response = await axios.get(
      `${applicationUri}api/content/v1/content-blocks.json?subsystem=ECMPROMOS&locations=${locationsQueryParams}`,
      {
        timeout: GENERIC_SERVICE_TIME_OUT,
      }
    );
    const structuredResponse = getStructuredEcmData(response.data, isMobile);
    return structuredResponse;
  } catch (err) {
    logger.error(
      REPONAME,
      fileName,
      "fetchEcmContentData",
      `error sending content-blocks.json request: ${JSON.stringify(err)}`
    );
  }
};

const getStructuredEcmData = (ecmData, isMobile) => {
  Object.getOwnPropertyNames(ecmData).forEach((content) => {
    ecmData[content] =
      !isMobile || ecmData[content].isDesktopAndMobileContentSame
        ? ecmData[content].desktopContents[0]
        : ecmData[content].mobileContents[0];
  });
  return ecmData;
};

// Utility to ensure URI has no ending slash
export const removeEndingSlashes = (path = "") =>
  path.slice(-1) === "/" && path.length > 1
    ? removeEndingSlashes(path.slice(0, -1))
    : path;
