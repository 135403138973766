<template>
  <svg
    data-style="facebook"
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.02 34.02"
    data-component="Global-social_icons-Facebook"
    aria-labelledby="facebook-icon"
  >
    <title id="facebook-icon">Facebook</title>
    <path
      :fill="fillColor"
      d="M17,0A17,17,0,1,0,34,17,17,17,0,0,0,17,0Zm3.75,14.13-.19,2.54H18v8.84h-3.3V16.67H12.9V14.13h1.76V12.42a4.57,4.57,0,0,1,.57-2.64A3.14,3.14,0,0,1,18,8.5a10.89,10.89,0,0,1,3.15.32l-.44,2.6a5.94,5.94,0,0,0-1.41-.21c-.69,0-1.3.25-1.3.93v2Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "Facebook",
  props: {
    fillColor: {
      type: String,
      default: "",
    },
  },
};
</script>
