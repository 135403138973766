<template>
  <form
    data-style="mobile-email-sign-up"
    id="mobile-footer-email-sign-up"
    :action="formAction"
    method="post"
    novalidate="novalidate"
    autocomplete="on"
    v-if="emailSignUp.enabled"
  >
    <div class="email-sign-up-copy-header">
      {{ emailSignUp.sign_up_copy_header }}
    </div>
    <div class="email-sign-up-copy">
      {{ emailSignUp.sign_up_copy }}
    </div>
    <div class="email-container">
      <input
        type="email"
        name="address.emailAddr"
        id="address.emailAddr"
        maxlength="75"
        :placeholder="emailSignUp.placeholder"
        v-model="emailId"
        required
      />
      <button type="submit" value="Submit" class="submit"></button>
    </div>
  </form>
</template>

<script>
export default {
  name: "mobileEmailSignUp",
  props: {
    emailSignUp: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      emailId: "",
      //FUTURE: The form action is using the DP code base currently as per brand requirement. A new UX/slide out mobile email sign up form implementation has to be done when all pages become MFE.
      formAction: "/customer-service/email-list",
    };
  },
};
</script>
