import axios from "axios";
import { REPONAME } from "../util/constants";
import { logger } from "@js-ecom-mfe/logger";
import { GENERIC_SERVICE_TIME_OUT } from "./const/timeouts";
import { currencyDetailsEndpoint } from "./const/endpoints";

const fileName = "countryDetails.js";

export const getCurrencyData = async (hostUrl, country, currency) => {
  const methodName = "getCurrencyData";

  try {
    const response = await axios.get(
      currencyDetailsEndpoint(hostUrl, country, currency),
      { timeout: GENERIC_SERVICE_TIME_OUT }
    );

    return response;
  } catch (error) {
    logger.error(REPONAME, fileName, methodName, error);
    return Promise.reject(error);
  }
};
