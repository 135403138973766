export const ACTIVE_TARGET_TESTS_KEY = "activeTargetTests";
export const APPLY_NOW = "Apply Now";

export const BREAKPOINTS = {
  extraSmall: 375,
  small: 768,
  medium: 990,
  large: 1440,
};

export const CCH_CHEVRON_RIGHT_ARROW = "cch-chevron-right";
export const CURRENT_STORES_LIST_KEY = "currentStoresList";

export const DEFAULT_INTERNATIONAL_COOKIE_VALUE = "US-USD-1";
export const DESKTOP_NAVIGATION = "desktopNavigation";

export const ECM_OVERLAY_CONTENT_TAG = `<script type="text/html" id="overlayTemplate_ecmOverlay">`;
export const ECM_OVERLAY_TRIGGER_CLASS = "overlayTrigger";

export const FAVORITES_COOKIE_NAME = "currentFavoriteList";
export const FAVORITES_LOCALSTORAGE_NAME = "favorites";
export const FLYOUT_CONFIG = {
  fallbackPlacementMessage: {
    enabled: true,
    message:
      "You don't have any recently viewed items. Here are some of our bestsellers!",
  },
  gridColumnCount: 1,
  apiErrorMessage: "There is no recent or best seller items.",
  inlineRecommendations: {
    maskUrl: {
      enable: false,
      hostUrl: "svc/recommendation/v2/",
    },
    breakpoints: {
      large: {
        itemsToShow: 4,
        itemsToSlide: 4,
      },
      medium: {
        itemsToShow: 4,
        itemsToSlide: 4,
      },
      small: {
        itemsToShow: 3,
        itemsToSlide: 3,
      },
      xSmall: {
        itemsToShow: 2.5,
        itemsToSlide: 2,
      },
    },
  },
};

export const GOVERNOR_USER_STATE_KEY = "userDisplayName";

export const LOGOUT_API_URL = "/api/user/v2/signout.json";

export const LOGOUT_URL = "/account/logout.html";

export const INLINE_RECOMMENDATIONS_BREAKPOINTS = {
  large: {
    itemsToShow: 6,
    itemsToSlide: 6,
  },
  medium: {
    itemsToShow: 6,
    itemsToSlide: 6,
  },
  small: {
    itemsToShow: 3,
    itemsToSlide: 3,
  },
  xSmall: {
    itemsToShow: 2.5,
    itemsToSlide: 2,
  },
};

export const VTA_RVI_BREAKPOINTS = {
  large: {
    itemsToShow: 6,
    itemsToSlide: 6,
  },
  medium: {
    itemsToShow: 6,
    itemsToSlide: 6,
  },
  small: {
    itemsToShow: 6,
    itemsToSlide: 6,
  },
  xSmall: {
    itemsToShow: 6,
    itemsToSlide: 6,
  },
};

export const LAT = "lat";
export const LAT_LONG = "latlong";
export const LAT_LONG_PROP = "latLongAddress";
export const LEARN_MORE = "Learn More";
export const LNG = "lng";
export const LOYALTY_REWARDS_RECOGNITION_COOKIE = "LOY_RWRDS_RCGNITN";

export const MIN_DESKTOP_WIDTH = 992;
export const MOBILE_NAVIGATION = "mobileNavigation";
export const MY_STORE_KEY = "myStore";

export const PERSISTENT_LOGIN_KEY = "isPersistentLoginChecked";
export const PICKUP_ADDRESS = "address";
export const PICKUP_LATITUDE = "latitude";
export const PICKUP_LONGITUDE = "longitude";
export const PRICE_TYPE_BW_RANGE = true;
export const PRICING_CONFIG = {
  showDiscountPercentage: false,
  showMdmPriceLabels: true,
  showPriceTypeBetweenPriceRanges: true,
  mdmLabels: {
    discountPercentage: "[disc_percentage]",
    regular: "",
    clearance: "Clearance",
    discount: "Limited Time Offer",
  },
  defaultLabels: {
    discountPercentage: "[disc_percentage]",
    regular: "",
    clearance: "Clearance",
    sale: "Clearance",
    special: "Limited Time Offer",
    discount: "Limited Time Offer",
  },
};
export const PRODUCT_CAROUSAL_CONFIG = {
  arrowsInside: true,
  ratingEnabled: false,
  verticalRviArrowsInside: true,
};
export const PROMOVIZ_CONFIG = {
  enabled: true,
  locations: {
    shopEnabled: true,
    recommendationEnabled: true,
    pipEnabled: true,
  },
  ineligibleLocations: {
    shopEnabled: false,
    recommendationEnabled: false,
    pipEnabled: false,
  },
  lazyLoadEnabled: false,
  eligibilityExcludeNLA: false,
  additionalMessagePip: "price_with_offer",
  additionalMessageThumbnail: "price_with_offer",
  thumbnailDisplayMessageCount: 1,
  pipDisplayMessageCount: 1,
  ineligibleThumbnailDisplayMessageCount: 1,
  ineligiblePipDisplayMessageCount: 1,
  visibilitySkuCount: 100,
  cutOffPercent: 1,
  retryMax: 5,
  backOffInterval: 10,
};

export const RECOMMENDATION_VARIANT = "default";
export const RECOMMENDATIONS_CONFIG = {
  isRecsEnabled: true,
  showSeeMoreLink: true,
  wsiRecs: {
    breakpoints: {
      large: {
        itemsToShow: 4,
        itemsToSlide: 4,
      },
      medium: {
        itemsToShow: 4,
        itemsToSlide: 4,
      },
      small: {
        itemsToShow: 3,
        itemsToSlide: 3,
      },
      xSmall: {
        itemsToShow: 2.5,
        itemsToSlide: 2,
      },
    },
    recsHostUrl: "svc/recommendation/v2/",
    variant: "Default",
  },
};
export const RVI_LABELS = ["Recent", "Recently Viewed"];
export const DEFAULT_PAGE_TYPE_VTA_RVI = "VTARVI";

export const SEARCH_BAR_KEY = "searchBarInteraction";
export const SEARCH_BAR_PROPERTY = "searchType";
export const SELECTED_FACETS = "selectedFacets";
export const SRC_PLACEHOLDER =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIwAAACMCAQAAAAEyYm1AAAAvklEQVR42u3QMQEAAAgDINc/rBG0gLcXRCA9xSFixIgRI0aMGDFixIgRgxgxYsSIESNGjBgxYhAjRowYMWLEiBEjRgxixIgRI0aMGDFixIhBjBgxYsSIESNGjBgxYiSIESNGjBgxYsSIESMGMWLEiBEjRowYMWLEIEaMGDFixIgRI0aMGMSIESNGjBgxYsSIEYMYMWLEiBEjRowYMWLEIEaMGDFixIgRI0aMGMSIESNGjBgxYsSIEYMYMWLEfFgX+g7EW9v8SgAAAABJRU5ErkJggg==";
export const STORE_LOCATION_CLOSED_FORMATTED = "Closed";
export const STORE_PICKUP_KEY = "storePickup";
export const STORES_KEY = "stores";
export const SUB_BRAND_CONTEXT = "subBrandContext";
export const STORE_LIST_SESSION_STORAGE_NAME = "myStoreList";
export const PAGE_SESSION_DATA_KEY = "pageData";

export const TTL = "ttl";
export const TYPE_AHEAD_RECENTS_KEY = "recentSearches";

export const RECENT_SEARCHES_TITLE = "RECENT SEARCHES";

export const MIN_QUERY_INPUT_LIMIT = 3; // for Search Suggestions
export const RVI_MAX_VISIBLE = 8;
export const RVI_ITEMS_PER_ROW = 4;

export const POPULAR_SEARCHES_MAX_RETRIES = 2;

export const US_MARKET_CODE = "USA";
export const US_COUNTRY_CODE = "US";
export const US_CURRENCY_CODE = "USD";
export const USER_LOCATION_KEY = "currentZipCodeInfo"; // ship to
export const STORE_LOCATION_KEY = "storeZipCodeInfo"; // my store key
export const CANADA_COUNTRY_CODE = "CA";
export const SUPPORTING_TEXT_GLOBAL_REDIRECT = "Looks like you're in";
export const IVR_PREF_VISITOR_STATUS_NEW = "new";
export const IVR_PREF_VISITOR_STATUS_RETURNING = "returning";
export const IVR_PREF_HIDE_SITE_LINK_DISMISS = "dismiss";
export const IVR_COOKIE_KEY = "IVR_PREF";
export const CURRENT_ZIP_CODE_INFO = "WSGEO";
export const IVR_IMAGE_PATH = "netstorage/images/global-mfe/img/country_flags/";
export const AUTO_SUGGEST_BASE_URI =
  "https://dev.virtualearth.net/REST/v1/Autosuggest";
export const AUTO_SUGGEST_MAX_RESULTS = 5;
export const REPONAME = "ecom-app-global";
export const SIGN_OUT = "SIGN OUT";

export const PHONE_NO_REGEXP =
  /(\([2-9]{1}[0-8]{1}[0-9]{1}\) [0-9]{3}-[0-9]{4})/gm;
export const MOBILE_EMAIL_OVERLAY_V2_VERSION = 2;
export const GLOBAL_NETSTORAGE_PATH = "netstorage/images/global-mfe/";

export const SESSION_STORAGE_CACHE_TTL = 3_600_000; // one hour TTL

export const RECENTLY_SEARCHED_MAX_SIZE = 3;

export const ORDER_CONFIRMATION_URL = "/checkout/thanks.html?ordernum=";
export const B2B_RTC_SESSION = "B2B RTC SESSION";
export const RTC_SESSION = "RTC SESSION";

export const PROFILE_SUMMARY_LOCAL_STORAGE_KEY = "profileSummary";
export const UI_USERNAME_COOKIE = "UI_USERNAME_COOKIE";
export const B2B_MEMBER_INDICATOR = "b2bmember";
export const NON_B2B_MEMBER = "non-member";
export const B2B_MEMBER = "member";

export const SEARCH_ENGINE_CONSTRUCTOR_AB_VARIANT = "variant";

export const EMAIL_OVERLAY_SUPPRESSION_DURATION_IN_DAYS = 400;
export const DEFAULT_POSTAL_CODE = "M6A2T9";

export const FIND_REGISTRY_HEADING = "Find A Registry";
export const FIND_REGISTRY_BUTTON_TEXT = "Find A Registry";
export const MANAGE_MY_REGISTRY_HEADING = "Manage My Registry";
export const MANAGE_LINKS_TOP_PICS = "Top Picks";
export const MANAGE_LINKS_MANAGE_MY_REGISTRY = "Manage My Registry";
export const MANAGE_LINKS_LINK_YOUR_STORE = "Link Your Store Registry";
export const SIGN_IN_TEXT = "Sign in to your registry with your account.";
export const GET_STARTED_BUTTON_TEXT = "Get Started";

export const MOBILE_NAV_ACTIVE_TAB = "mobileNavActiveTab";
export const MOBILE_NAV_ACTIVE_TAB_DEFAULT_EXPIRATION_DAYS = 30;

export const REGISTERED_USER_COOKIE = "userregistered";
export const CUSTOM_POSTAL_CODE_FORMAT_REGEX =
  "^([A-Za-z]\\d[A-Za-z]|[A-Za-z]\\d[A-Za-z]\\s?\\d[A-Za-z]\\d)$";

export const MY_STORE_UPDATE_EVENT = "onMyStoreUpdate";

export const STORE_UPDATE_EVENT = "updateGlobalMyStore";
export const AODD_POSTALCODE_COOKIE = "zipCodeAvailability";
export const AODD_WSGEO_COOKIE = "WSGEO";
