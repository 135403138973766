export default {
  state: () => ({
    concept: "",
    applicationUri: "",
  }),
  getters: {
    getConcept: (state) => {
      return state.concept;
    },
  },
};
