<template>
  <ul
    data-style="footer-menu"
    class="footer-menu"
    :class="{
      expand: menuActive,
    }"
    @click="toggleFooterMenu()"
  >
    <template v-for="(foot_link, index) in footer_link.column">
      <template
        v-if="
          (foot_link.text && !foot_link.custom_component) || foot_link.image_url
        "
      >
        <li :key="index">
          <template v-if="foot_link.text">
            <h3
              v-if="foot_link.type === 'header' && !foot_link.link"
              class="footer-heading"
            >
              {{ foot_link.text }}
            </h3>
            <h3 v-else-if="foot_link.type === 'header'" class="footer-heading">
              <a :href="foot_link.link">{{ foot_link.text }}</a>
            </h3>
            <a
              v-else-if="foot_link.type === 'feedback'"
              :href="foot_link.link"
              @click="launchOO($event)"
              class="footer-feedback"
            >
              {{ foot_link.text }}
            </a>
            <a
              v-else-if="
                foot_link.text.includes('Key Rewards') ||
                foot_link.text === 'View My Rewards' ||
                foot_link.text === 'The Key'
              "
              :href="updateKeyRewardsLink(foot_link)"
              class="footer-link"
              @click="addAnalyticsEvent(foot_link)"
              :target="foot_link.target"
              >{{ foot_link.text }}</a
            >
            <a
              v-else
              :href="foot_link.link"
              class="footer-link"
              @click="addAnalyticsEvent(foot_link)"
              :target="foot_link.target"
              >{{ foot_link.text }}</a
            >
          </template>
          <template v-else-if="!foot_link.text && foot_link.image_url">
            <a :href="foot_link.link">
              <img
                :src="foot_link.image_url"
                :alt="foot_link.image_txt"
                loading="lazy"
              />
            </a>
          </template>
          <template v-if="foot_link.secondary_text">
            <br />
            <span class="last-updated">{{ foot_link.secondary_text }}</span>
          </template>
        </li>
      </template>
      <template
        v-else-if="
          (!foot_link.text &&
            foot_link.custom_component &&
            foot_link.component !== 'ShippingCountry') ||
          (foot_link.component === 'ShippingCountry' &&
            shippingCountryEnabled &&
            hasShippingCountryInFooter)
        "
      >
        <li :key="index">
          <component :is="foot_link.component"></component>
        </li>
      </template>
    </template>
  </ul>
</template>

<script>
import { addEvent } from "@js-ecom-tracking/datalayer";
import breakPoints from "../../mixins/breakPoints";
import ClosestStoreWidget from "./ClosestStoreWidget.vue";
import Feedback from "./Feedback.vue";
import onlineOpinions from "../../mixins/onlineOpinions";
import ShippingCountry from "../ShippingCountry.vue";
import WSCreditCard from "./WSCreditCard.vue";
import { APPLY_NOW, LEARN_MORE } from "../../util/constants.js";

import { getConfigById, getKeyRewardsLink } from "../../util/context";

export default {
  components: {
    WSCreditCard,
    ClosestStoreWidget,
    Feedback,
    ShippingCountry,
  },

  data() {
    const {
      hasShippingCountryInFooter = false,
      shippingCountryEnabled = false,
    } = getConfigById("i18n", this) || {};

    return {
      shippingCountryEnabled,
      hasShippingCountryInFooter,
      menuActive: false,
      brandCreditCardName: getConfigById("creditCardServiceProviderName", this),
    };
  },

  mounted() {
    this.addEvent = addEvent;
  },

  methods: {
    // Set active state of footer menu accordion only for mobile
    toggleFooterMenu() {
      if (this.isMediumBreakPoint()) {
        this.menuActive = !this.menuActive;
      }
    },
    addAnalyticsEvent(footLink) {
      if (this.brandCreditCardName === "COF") {
        if (footLink.text === APPLY_NOW) {
          this.addEvent({
            category: "credit card application",
            item: "apply now interaction",
            clickLocation: "creditcard-_-footer-_-applynow",
          });
        } else if (footLink.text === LEARN_MORE) {
          this.addEvent({
            category: "credit card application",
            item: "learn more interaction",
            clickLocation: "creditcard-_-footer-_-learnmore",
          });
        }
      }
    },
    updateKeyRewardsLink(link) {
      return getKeyRewardsLink(link);
    },
  },

  mixins: [breakPoints, onlineOpinions],

  props: {
    footer_link: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
