<template>
  <div
    data-style="ccpa-header-button"
    v-if="display"
    class="ccpa-opt-out-button-container"
    data-component="Global-header-CCPAHeaderButton"
  >
    <a class="ccpa-opt-out-button" :href="ccpaLink" target="_blank">
      Do Not Sell My Personal Information
    </a>
  </div>
</template>

<script>
import { getConfigById } from "../../util/context";

export default {
  data() {
    return {
      display: true,
    };
  },
  created() {
    const config = getConfigById("ccpa", this) ?? {};
    this.display = !config?.hideCCPA ?? true;
    this.ccpaLink =
      config?.doNotSellMyPersonalInformationLink ??
      "/about-us/privacy-policy.html#do-not-sell";
  },
};
</script>
