<template>
  <div
    data-style="marketing-notification"
    class="marketing-opt-in"
    v-if="isEmailSignUpEnabled"
  >
    <div class="marketing-email-opt-in-checkbox">
      <input
        type="hidden"
        name="flowType"
        :value="flowType"
        class="flow-type"
        ref="flowType"
      />
      <input
        type="checkbox"
        class="email-opt-in"
        v-model="isDefaultEmailOptInSelected"
        :value="isDefaultEmailOptInSelected"
        :name="emailSignUp"
        id="marketing-checkbox"
        ref="notificationOptIn"
      />
      <label for="marketing-checkbox"></label>
      <div class="marketing-notification-text">
        {{ marketingNotificationText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MarketingNotification",
  props: {
    isEmailSignUpEnabled: {
      type: Boolean,
      default: false,
    },
    isDefaultEmailOptInSelected: {
      type: Boolean,
      default: false,
    },
    marketingNotificationText: {
      type: String,
      default: "",
    },
    emailSignUp: {
      type: String,
      default: "",
    },
    flowType: {
      type: String,
      default: "",
    },
  },
};
</script>
