import { loadScript } from "../util/scriptHandler";

/**
 * Get the state short name from google's address component
 *
 * @param {object} address -- address component from google place service
 * @param {string} type -- there are two types "short_name" and "long_name". Defaulted to "short_name"
 * @return {string} 2 letter state abbreviation
 *
 */
const stateFromAddComp = (address, type = "short_name") => {
  const stateDetails = address.find(
    (entry) => !!entry.types.includes("administrative_area_level_1")
  );
  if (stateDetails) {
    return stateDetails[type];
  } else {
    return "";
  }
};

/**
 * Get postal code from google's address component
 *
 * @param {object} address -- address component from google place service
 * @param {string} type -- there are two types "short_name" and "long_name". Defaulted to "long_name"
 *
 * @return {string} zipcode
 *
 */
export function zipCodeFromAddComp(address, type = "long_name") {
  const zipCodeDetails = address.find(
    (postal) => postal.types[0] === "postal_code"
  );
  if (zipCodeDetails) {
    return zipCodeDetails[type];
  }
}

/**
 * Get the state short name from google's address component
 *
 * @param {object} address -- address component from google place service
 *
 * @return {string} 2 letter state abbreviation
 *
 */
export function latLngFromAddComp(address) {
  if (address && address.geometry) {
    const { location } = address.geometry;
    return {
      lat: location.lat(),
      lng: location.lng(),
    };
  }
}

/**
 * This will search for a lat/lng given an address.
 *
 * @param {string} address to lookup. you could pass zipcode as well
 *
 * @return {Promise} On success the resolve will be called with the coordinates, on failure the reject will be called with the error
 *
 */
export function fetchAddressFromZipCode(address) {
  return new Promise((resolve, reject) => {
    if (window.google && window.google.maps) {
      const geo = new window.google.maps.Geocoder();
      geo.geocode({ address }, (results, status) => {
        if (status === "OK" && results.length) {
          resolve({
            ...latLngFromAddComp(results[0]),
            address: results[0].formatted_address,
            state: stateFromAddComp(results[0].address_components),
            zipCode: zipCodeFromAddComp(results[0].address_components),
          });
        } else {
          reject(status);
        }
      });
    } else {
      reject("Google Maps not defined");
    }
  });
}

/**
 * This will search for a lat/lng given an city search term by chaining two geocoder lookups:
 * - First geocoder lookup for lat/lng using the city keyword, which does not return a zip code.
 * - Second geocoder lookup for the full geocoder object using the lat/lng returned from the first call.
 *
 * @param {string} address to lookup.
 *
 * @return {Promise} On success the resolve will be called with the coordinates, on failure the reject will be called with the error
 *
 */
export function fetchAddressFromCityKeyword(address) {
  return new Promise((resolve, reject) => {
    if (window.google && window.google.maps) {
      const geo = new window.google.maps.Geocoder();
      geo.geocode({ address }, (results, status) => {
        if (status === "OK" && results.length) {
          resolve(fetchAddressFromLatLng(latLngFromAddComp(results[0])));
        } else {
          reject(status);
        }
      });
    } else {
      reject("Google Maps not defined");
    }
  });
}

/**
 * This will search for address based on passed lat/long.
 *
 * @param {object} latlng to lookup. should be in the following format {lat: xx, lng: xx}
 *
 * @return {Promise} On success the resolve will be called with the coordinates, on failure the reject will be called with the error
 *
 */
export function fetchAddressFromLatLng(latlng) {
  return new Promise((resolve, reject) => {
    if (window.google && window.google.maps) {
      const geo = new window.google.maps.Geocoder();
      geo.geocode({ location: latlng }, (results, status) => {
        if (status === "OK" && results.length) {
          resolve({
            lat: latlng.lat,
            lng: latlng.lng,
            address: results[0].formatted_address,
            state: stateFromAddComp(results[0].address_components),
            zipCode: zipCodeFromAddComp(results[0].address_components),
          });
        } else {
          reject(status);
        }
      });
    } else {
      reject("Google Maps not defined");
    }
  });
}

/**
 * This loads the google maps library
 *
 * @param {Object} googleMapsConfig -- Concept specific config that specifies path and clientId
 *
 * @return {Promise} Returns a promise
 *
 */
export async function loadGoogleMapLib(googleMapsConfig) {
  await loadScript(
    `${googleMapsConfig.path}?v=3.36&client=${googleMapsConfig.clientId}&libraries=places`
  );
}
