import Vue from "vue";
import Vuex from "vuex";
import header from "./modules/header";
import footer from "./modules/footer";

Vue.use(Vuex);

export function createHeaderStore() {
  return new Vuex.Store({
    modules: {
      header,
    },
  });
}

export function createFooterStore() {
  return new Vuex.Store({
    modules: {
      footer,
    },
  });
}
