<template>
  <svg
    data-style="youtube2"
    width="26px"
    height="30px"
    viewBox="0 0 26 26"
    role="img"
    class="icon-youTube"
    data-component="Global-social_icons-YouTube2"
    aria-labelledby="youtube2-icon"
  >
    <title id="youtube2-icon">youTube</title>
    <circle
      id="Oval-Copy-5"
      stroke="#363636"
      stroke-width="0.5"
      fill="#363636"
      cx="12.5"
      cy="12.5"
      r="12.5"
    ></circle>
    <path
      d="M10.9675725,14.8191076 L10.9675725,10.6590656 L14.9565228,12.7390866 L10.9675725,14.8191076 Z M19.7805922,9.99025237 C19.5478835,7.99817012 18.7558364,7.72119292 17.1860995,7.61530746 C14.951737,7.46156418 9.96016515,7.46156418 7.72640085,7.61530746 C6.15606575,7.72119292 5.36461686,7.99817012 5.13190815,9.99025237 C4.95603062,11.4678629 4.95603062,13.9965511 5.13190815,15.4789474 C5.36461686,17.4710297 6.15606575,17.7480069 7.72640085,17.8538923 C9.96495094,18.0076356 14.951737,18.0076356 17.1860995,17.8538923 C18.7558364,17.7480069 19.5478835,17.4710297 19.7805922,15.4789474 C19.9564698,14.0013369 19.9564698,11.4726487 19.7805922,9.99025237 L19.7805922,9.99025237 Z"
      fill="#FFFFFF"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "Youtube2",
  props: {
    fillColor: {
      type: String,
      default: "",
    },
  },
};
</script>
