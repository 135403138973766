import axios from "axios";
import { logger } from "@js-ecom-mfe/logger";
import { GENERIC_SERVICE_TIME_OUT } from "./const/timeouts";
import { closestStoreEndpoint } from "./const/endpoints";
import { REPONAME } from "../util/constants";

const fileName = "closestStore.js";

export const getClosestStore = async (
  brand,
  latitude,
  longitude,
  radius = 200,
  includeOutlets = false
) => {
  try {
    let res = await axios.get(
      closestStoreEndpoint(brand, latitude, longitude, radius, includeOutlets),
      {
        timeout: GENERIC_SERVICE_TIME_OUT,
      }
    );

    return res;
  } catch (error) {
    logger.error(REPONAME, fileName, "getClosestStore", error);
    return Promise.reject(error);
  }
};
