<template>
  <div
    data-style="financial-incentives"
    class="financial-incentive-terms-container"
    v-if="fin_details.terms"
    data-component="Global-footer-FinancialIncentives"
  >
    <p v-html="fin_details.terms"></p>
    <p v-html="fin_details.secondary_terms"></p>
  </div>
</template>

<script>
import { getConfigById } from "../../util/context";
import { logger } from "@js-ecom-mfe/logger";
import { REPONAME } from "../../util/constants";

const fileName = "FinancialIncentives.vue";
export default {
  name: "financialIncentives",
  methods: {
    getFinDetails() {
      const config = getConfigById("financialIncentives", this);
      if (!config) {
        logger.warn(
          REPONAME,
          fileName,
          "getFinDetails",
          `Parsing error in config for financialIncentives`
        );
        return [];
      }
      return config;
    },
  },
  created() {
    this.fin_details = this.getFinDetails();
  },
};
</script>
