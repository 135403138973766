export const brConfigEndpoint = (host) =>
  `${host}api/catalog/v1/searchprovider/settings.json`;

export const categoryDataEndpoint = () =>
  `/api/catalog/v1/category/categorytree/shop/data.json`;

export const closestStoreEndpoint = (
  brand,
  latitude,
  longitude,
  radius,
  includeOutlets
) =>
  `/search/stores.json?brands=${brand}&lat=${latitude}&lng=${longitude}&radius=${radius}&includeOutlets=${includeOutlets}`;

export const currencyDetailsEndpoint = (hostUrl, country, currency) =>
  `${hostUrl}international/currencyDetails/index.json?country=${country}&currency=${currency}`;

export const deliveryInformationEndpoint = (postalCode) =>
  `/cacheable/availability/getDeliveryInformationByPostalCode.json?postalCode=${postalCode}`;

export const favoritesListEndpoint = (listId) =>
  `/favorites/${listId}/list.json`;

export const loginStateEndpoint = (applicationUri) =>
  `${applicationUri}account/syncUiLoginState/index.json`;

export const productGroupEndpoint = (groupId) =>
  `/api/catalog/v1/catalogobjects/${groupId}/widget.json`;

export const quickCartEndpoint = () =>
  "/shoppingcart/fragment/quickcart-mfe.html";

export const countryDetailsEndpoint = () =>
  `/international/countryDetails-mfe.html`;

export const popularSearchesEndpoint = (configuredPath, searchDate) => {
  return `${configuredPath}${searchDate}.csv`;
};

export const loyaltyEndpoint = "/api/loyalty/v1/keyrewards";

export const profileSummaryEndpoint =
  "/api/profile/v1/account/me/profile-summary";
