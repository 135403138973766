import { logger } from "@js-ecom-mfe/logger";
import { getClosestStore } from "../services/closestStore";
import { REPONAME } from "../util/constants";
import { getBrandConcept } from "../util/context";

const fileName = "storeLocator.js";
export default {
  methods: {
    updateStoreInfo(store) {
      /* eslint-disable camelcase */
      this.store_name = store.STORE_NAME;
      this.store_address = store.ADDRESS_LINE_1;
      this.store_address2 = store.ADDRESS_LINE_2;
      this.store_locale = `${store.CITY}, ${store.STATE_PROVINCE} ${store.POSTAL_CODE}`;
      this.store_url = this.getStoreUrl(store);
      this.store_phone = store.PHONE_NUMBER_FORMATTED;

      this.data_layer_event = `{ 'type' : 'find-store-by-store-zip', 'currentCoordinates': { 'latitude': ${store.LATITUDE} , 'longitude': ${store.LONGITUDE} }, 'currentStoreNumber': ${store.STORE_NUMBER}, 'searchRadius': 100, 'searchZip': ${store.POSTAL_CODE} }`;
      this.store = store;
      /* eslint-enable camelcase */
    },
    getStoreUrl(store) {
      function parseName(name = "") {
        //remove dots, replace whitespace characters and slashes with hyphens, and convert to lowercase
        return name
          .replace(/\./g, "")
          .replace(/(\/|[ ]+)/g, "-")
          .toLowerCase();
      }

      const parsedName = parseName(store.STORE_NAME);
      const parsedCity = parseName(store.CITY);
      const normalizedId =
        parsedName.indexOf(parsedCity) === 0
          ? parsedName
          : `${parsedCity}-${parsedName}`;

      return `/stores/${store.COUNTRY_CODE.toLowerCase()}/${store.STATE_PROVINCE.toLowerCase()}/${normalizedId}/`;
    },
    getNearestStoreDetails(locationKey) {
      const updateStoreInfo = this.updateStoreInfo;
      const brand = getBrandConcept(this);
      async function locateNearestStore(position) {
        try {
          const { data } = await getClosestStore(
            brand,
            position.coords.latitude,
            position.coords.longitude
          );
          if (data?.storeListResponse?.stores?.length) {
            updateStoreInfo(data.storeListResponse.stores[0].properties);
          }
        } catch (e) {
          logger.error(REPONAME, fileName, "locateNearestStore", e);
        }
      }

      // get latitude and longitude from governor state location details which is set from akamai cookie
      // WSGEO
      window.WSI.state.onChange(locationKey, (geoInfo) => {
        const locationDetails = geoInfo.split("|");
        locateNearestStore({
          coords: {
            latitude: locationDetails[3],
            longitude: locationDetails[4],
          },
        });
      });
    },
  },
};
