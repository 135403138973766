<template>
  <div
    data-style="modal"
    class="shader"
    @click.self="close"
    data-component="Global-Modal"
    role="dialog"
    @keydown="checkKeyDown"
  >
    <div class="modal-scroll">
      <dialog class="modal_component" open="open" ref="modalDialogRef">
        <button
          type="button"
          class="btnClose"
          aria-label="close-global-modal"
          @click="close"
          @keyup.esc="close"
          ref="closeButtonRef"
        >
          x
        </button>
        <section class="modalBody">
          <slot></slot>
        </section>
      </dialog>
    </div>
  </div>
</template>

<script>
import focusTrap from "../mixins/focusTrap";
// This component is intended to be shown or hidden by its parent component via the "v-close" attribute
// Its close handler can be listened to with the @close="" attribute
export default {
  name: "modal",
  props: {
    showCloseBtn: {
      type: Boolean,
      default: true,
    },
    focusTrapActive: {
      type: Boolean,
      default: true,
    },
    initialFocusOnModal: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [focusTrap],
  data() {
    return {};
  },
  updated() {
    //This is needed for accessibility on the modals.
    //sets the trigger element to be restored on close,
    //sets initial focus within the modal, gets all focusable elements for tabbing,
    //and sets the closer function for when the escape key is pressed
    if (this.focusTrapActive && this.initialFocusOnModal) {
      this.setFocusTriggerEl(document.activeElement);
      this.$refs.closeButtonRef.focus();
      this.getFocusableElements(this.$refs.modalDialogRef);
      this.setCloserFunction(this.close);
    }
  },
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
