var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"footer-menu",class:{
    expand: _vm.menuActive,
  },attrs:{"data-style":"footer-menu"},on:{"click":function($event){return _vm.toggleFooterMenu()}}},[_vm._l((_vm.footer_link.column),function(foot_link,index){return [(
        (foot_link.text && !foot_link.custom_component) || foot_link.image_url
      )?[_c('li',{key:index},[(foot_link.text)?[(foot_link.type === 'header' && !foot_link.link)?_c('h3',{staticClass:"footer-heading"},[_vm._v("\n            "+_vm._s(foot_link.text)+"\n          ")]):(foot_link.type === 'header')?_c('h3',{staticClass:"footer-heading"},[_c('a',{attrs:{"href":foot_link.link}},[_vm._v(_vm._s(foot_link.text))])]):(foot_link.type === 'feedback')?_c('a',{staticClass:"footer-feedback",attrs:{"href":foot_link.link},on:{"click":function($event){return _vm.launchOO($event)}}},[_vm._v("\n            "+_vm._s(foot_link.text)+"\n          ")]):(
              foot_link.text.includes('Key Rewards') ||
              foot_link.text === 'View My Rewards' ||
              foot_link.text === 'The Key'
            )?_c('a',{staticClass:"footer-link",attrs:{"href":_vm.updateKeyRewardsLink(foot_link),"target":foot_link.target},on:{"click":function($event){return _vm.addAnalyticsEvent(foot_link)}}},[_vm._v(_vm._s(foot_link.text))]):_c('a',{staticClass:"footer-link",attrs:{"href":foot_link.link,"target":foot_link.target},on:{"click":function($event){return _vm.addAnalyticsEvent(foot_link)}}},[_vm._v(_vm._s(foot_link.text))])]:(!foot_link.text && foot_link.image_url)?[_c('a',{attrs:{"href":foot_link.link}},[_c('img',{attrs:{"src":foot_link.image_url,"alt":foot_link.image_txt,"loading":"lazy"}})])]:_vm._e(),_vm._v(" "),(foot_link.secondary_text)?[_c('br'),_vm._v(" "),_c('span',{staticClass:"last-updated"},[_vm._v(_vm._s(foot_link.secondary_text))])]:_vm._e()],2)]:(
        (!foot_link.text &&
          foot_link.custom_component &&
          foot_link.component !== 'ShippingCountry') ||
        (foot_link.component === 'ShippingCountry' &&
          _vm.shippingCountryEnabled &&
          _vm.hasShippingCountryInFooter)
      )?[_c('li',{key:index},[_c(foot_link.component,{tag:"component"})],1)]:_vm._e()]})],2)
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }