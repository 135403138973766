<template>
  <svg
    data-style="twitter"
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.02 34.02"
    data-component="Global-social_icons-Twitter"
    aria-labelledby="twitter-icon"
  >
    <title id="twitter-icon">Twitter</title>
    <path
      :fill="fillColor"
      d="M17,0h0a17,17,0,0,0,0,34h0A17,17,0,0,0,17,0Zm6.77,13.53c0,.15,0,.3,0,.46A10,10,0,0,1,17,23.45a10.52,10.52,0,0,1-3.16.48,9.91,9.91,0,0,1-5.36-1.57,7.55,7.55,0,0,0,.83.05,7,7,0,0,0,4.34-1.5,3.48,3.48,0,0,1-3.26-2.42A3.62,3.62,0,0,0,12,18.43,3.52,3.52,0,0,1,9.17,15v0a3.56,3.56,0,0,0,1.59.43A3.5,3.5,0,0,1,9.2,12.48a3.42,3.42,0,0,1,.48-1.76,9.92,9.92,0,0,0,7.2,3.66,3.57,3.57,0,0,1-.09-.8A3.53,3.53,0,0,1,17,12.4a3.48,3.48,0,0,1,5.83-1.21,7.2,7.2,0,0,0,2.22-.85,3.48,3.48,0,0,1-1.54,1.93,6.8,6.8,0,0,0,2-.55A7,7,0,0,1,23.78,13.53Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "Twitter",
  props: {
    fillColor: {
      type: String,
      default: "",
    },
  },
};
</script>
