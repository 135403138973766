/**
 * Returns the event’s path which is an array of the objects on which listeners will be invoked.
 * This property, along with composedPath, is already available in modern browsers so this
 * helper function will mainly be used for IE11 compatibility.
 * Ref: http://stackoverflow.com/questions/36845515/mouseevent-path-equivalent-in-firefox-safari
 * @param e - MouseEvent
 * @returns {Array|EventTarget[]|*}
 */
export const getEventPath = (e) => {
  if ("composedPath" in e) {
    return e.composedPath();
  }
  if ("path" in e) {
    return e.path;
  }
  //polyfill
  const path = [];
  let currentElem = e.target;
  while (currentElem) {
    path.push(currentElem);
    currentElem = currentElem.parentElement;
  }
  if (path.indexOf(window) === -1 && path.indexOf(document) === -1) {
    path.push(document);
  }
  if (path.indexOf(window) === -1) {
    path.push(window);
  }
  return path;
};
