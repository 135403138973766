<template>
  <form
    data-style="ws-credit-card"
    id="instantCreditPromoForm"
    action="https://www.williams-sonoma.com/creditcard/"
    method="POST"
    data-component="Global-footer-WSCreditCard"
  >
    <input type="hidden" name="referrerUrl" value="/" id="referrerUrl" />
    <a
      href="https://www.williams-sonoma.com/creditcard/?cm_type=fnav&amp;cm_re=shopbanner_default_cbcclanding"
    >
      <img
        src="https://www.williams-sonoma.com/wsimgs/rk/images/i/202003/0008/images/nav/footer/credit-card.png"
        alt="Williams Sonoma Visa Card"
      />
    </a>
  </form>
</template>

<script>
export default {
  name: "WSCreditCard",
};
</script>
