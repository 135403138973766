import { loadScript } from "../util/scriptHandler";
import { AUTO_SUGGEST_BASE_URI, AUTO_SUGGEST_MAX_RESULTS } from "./constants";

/**
 * Get the address's geometry from bing's address component
 *
 * @param {object} address -- address component from bing query manager
 *
 * @return {object} latitude and longitude
 *
 */
export function latLngFromBingAddComp(address) {
  if (address && address.geometry) {
    return {
      lat: address.geometry.y,
      lng: address.geometry.x,
    };
  }
}

/**
 * Get postal code from bing's address component
 *
 * @param {object} address -- address component from bing search service
 * @param {string} type -- there are two types "short_name" and "long_name". Defaulted to "long_name"
 *
 * @return {string} zipcode
 *
 */
export function zipCodeFromBingAddComp(address, type = "long_name") {
  const zipCodeDetails = address.find(
    (postal) => postal.types[0] === "postal_code"
  );
  if (zipCodeDetails) {
    return zipCodeDetails[type];
  }
}

/**
 * This will search for a lat/lng given an address.
 *
 * @param {string} address to lookup. you could pass zipcode as well
 *
 * @return {Promise} On success the resolve will be called with the coordinates, on failure the reject will be called with the error
 *
 */
export function fetchBingAddressFromZipCode(address, bingMapsConfig) {
  return _fetchBingAddressFromZip(address, null, bingMapsConfig);
}

/**
 * This will search for address based on passed lat/long.
 *
 * @param {object} latlng to lookup. should be in the following format {lat: xx, lng: xx}
 *
 * @return {Promise} On success the resolve will be called with the coordinates, on failure the reject will be called with the error
 *
 */
export function fetchBingAddressFromLatLng(latlng, bingMapsConfig) {
  return _fetchBingAddressFromZip(null, latlng, bingMapsConfig);
}

/**
 * This will search for address based on passed lat/long or address.
 *
 * @param {string} address to lookup. you could pass zipcode as well
 * @param {object} latlng to lookup. should be in the following format {lat: xx, lng: xx}
 * @param {object} bingMapsConfig -- Concept specific config that specifies path and clientId
 *
 * @return {Promise} On success the resolve will be called with the address object, on failure the reject will be called with the error
 */
function _fetchBingAddressFromZip(address, latlng, bingMapsConfig) {
  let bingRestEndpoint = "https://dev.virtualearth.net/REST/v1/",
    bingLocationService = "Locations",
    bingMapsLocationEndpoint = bingRestEndpoint + bingLocationService,
    url;
  const bingAPIKey = window.bingAPIKey ?? bingMapsConfig?.key;
  if (address) {
    url = `${bingMapsLocationEndpoint}?incl=ciso2&query=${encodeURIComponent(
      address
    )}&key=${bingAPIKey}`;
  } else if (latlng) {
    url = `${bingMapsLocationEndpoint}/${latlng.lat},${latlng.lng}?includeEntityTypes=Address,Postcode1&key=${bingAPIKey}`;
  }

  if (!url) {
    return Promise.reject(new Error("Bing Maps not defined"));
  }

  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data?.resourceSets[0]?.resources.length > 0) {
        return {
          lat: data.resourceSets[0].resources[0].geocodePoints[0]
            .coordinates[0],
          lng: data.resourceSets[0].resources[0].geocodePoints[0]
            .coordinates[1],
          address: data.resourceSets[0].resources[0].address.formattedAddress,
          state: data.resourceSets[0].resources[0].address.adminDistrict,
          zipCode: data.resourceSets[0].resources[0].address.postalCode,
        };
      }
      throw new Error("No resource sets found in response from: " + url);
    });
}

/**
 * This loads the bing maps library
 *
 * @param {Object} bingMapsConfig -- Concept specific config that specifies path and clientId
 *
 * @return {Promise} Returns a promise
 *
 */
export async function loadBingMapLib(bingMapsConfig) {
  window.bingAPIKey = bingMapsConfig.key;
  await loadScript(`${bingMapsConfig.path}&key=${bingMapsConfig.key}`);
}

/**
 *
 * @param {String} query URIencoded User's query taken from the search field
 * @param {Object} bingMapsConfig Brand Configuration for bing
 * @param {Object} userGeoLocation User's geo location details that includes countryCode, latitude, longitude set in governorState.
 * @returns {Array} of autosuggestion results fetch from the AutoSuggest Bing API.
 */
export const getAutoSuggestionResults = async (
  query,
  bingMapsConfig,
  userGeoLocation
) => {
  let results;
  const baseUri = bingMapsConfig?.autoSuggestURL
    ? bingMapsConfig.autoSuggestURL
    : AUTO_SUGGEST_BASE_URI;
  const maxResults = bingMapsConfig?.maxAutoSuggestResults
    ? bingMapsConfig.maxAutoSuggestResults
    : AUTO_SUGGEST_MAX_RESULTS;
  const serviceURL = `${baseUri}?query=${encodeURI(query)}&userLocation=${
    userGeoLocation.latitude
  },${userGeoLocation.longitude},10&userRegion=${
    userGeoLocation.country
  }&includeEntityTypes=Place,Business,Address&maxResults=${maxResults}&culture=en-${
    userGeoLocation.country
  }&key=${bingMapsConfig.key}`;
  let suggestionsArray = [];
  return new Promise((resolve, reject) => {
    if (serviceURL) {
      fetch(serviceURL)
        .then((response) => response.json())
        .then((data) => {
          if (data?.resourceSets?.[0]?.resources?.[0]?.value?.length) {
            data.resourceSets.forEach((set) => {
              set.resources.forEach((resource) => {
                resource.value.forEach((value) => {
                  suggestionsArray.push({
                    name: value.name ?? value.address.locality,
                    address: value.address.formattedAddress,
                    postalCode: value.address.postalCode ?? "",
                  });
                });
              });
            });
          }
          results = suggestionsArray;
          resolve(results);
        })
        .catch(reject);
    }
  });
};
