/**
 * This method promisfies loading of a script.
 * @param {String } src -- Script path
 *
 *  @returns {Promise} -- Which is resolves when script loads successfully and rejects when there is an error in loading the script
 */
export function loadScript(src, defer = true) {
  return new Promise(function (res, rej) {
    if (typeof src !== "string") {
      rej("Invalid argument");
      return;
    }
    //if script is already loaded res immediately
    if (document.querySelector('script[src="' + src + '"]')) {
      res();
      return;
    }

    const el = document.createElement("script");

    el.type = "text/javascript";
    el.async = true;
    el.defer = defer;
    el.src = src;

    el.addEventListener("load", res);
    el.addEventListener("error", rej);
    el.addEventListener("abort", rej);

    document.head.appendChild(el);
  });
}

/**
 * This method promisfies removing of a script from page
 * @param {String } src -- Script path
 *
 *  @returns {Promise} -- Which is resolves when script unloads successfully and rejects when there is an error in unloading the script
 */
export function unloadScript(src) {
  return new Promise(function (res, rej) {
    if (typeof src !== "string") {
      rej("Invalid argument");
      return;
    }
    const el = document.querySelector('script[src="' + src + '"]');

    if (!el) {
      rej("Script not found");
      return;
    }
    document.head.removeChild(el);
    res();
  });
}
