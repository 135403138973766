<template>
  <div
    data-style="closest-store-widget"
    v-if="store_name"
    class="closest-store-details"
    data-component="Global-footer-ClosestStoreWidget"
  >
    <div class="closest-store-heading">
      <h3>Your Local Store</h3>
    </div>
    <p class="closest-store-name">
      <svg
        width="11.828"
        height="15"
        viewBox="0 0 18 22.8"
        xmlns="http://www.w3.org/2000/svg"
        aria-labelledby="title"
      >
        <title>Store Locator</title>
        <path
          d="M15,26.5c-2.2,0-9-7.3-9-13.8,0-4.5,2.8-9,9-9s9,4.5,9,9C24,19.3,17,26.5,15,26.5ZM15,5.1c-5.6,0-7.6,4.1-7.6,7.6,0,6.1,6.5,12.3,7.6,12.4,1.1-.3,7.6-6.5,7.6-12.4C22.65,9.2,20.65,5.1,15,5.1Z"
          transform="translate(-6.05 -3.7)"
          fill="#363636"
        ></path>
        <path
          d="M15,16.3a3.48,3.48,0,0,1-3.5-3.5,3.5,3.5,0,0,1,7,0A3.48,3.48,0,0,1,15,16.3Zm0-5.7a2.11,2.11,0,0,0-2.1,2.1A2.18,2.18,0,0,0,15,14.8a2.18,2.18,0,0,0,2.1-2.1A2.11,2.11,0,0,0,15,10.6Z"
          transform="translate(-6.05 -3.7)"
          fill="#363636"
        ></path>
      </svg>
      <a :href="store_url" :data-layer-event="data_layer_event">{{
        store_name
      }}</a>
    </p>
    <p class="closest-store-address">{{ store_address }}</p>

    <p class="closest-store-locality">{{ store_locale }}</p>
    <p class="closest-store-phone">
      <a :href="`tel:${store_phone}`">{{ store_phone }}</a>
    </p>
  </div>
</template>

<script>
import storeLocator from "../../mixins/storeLocator";

export default {
  name: "ClosestStoreWidget",
  data() {
    return {
      data_layer_event: "",
      store_name: "",
      store_address: "",
      store_locale: "",
      store_url: "",
      store_phone: "",
    };
  },
  mixins: [storeLocator],
  mounted() {
    WSI.state.onChange("storeListResponse", (data) => {
      if (data?.storeListResponse?.stores?.length) {
        this.updateStoreInfo(data.storeListResponse.stores[0].properties);
      }
    });
  },
};
</script>
