<template>
  <svg
    data-style="pinterest"
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.02 34.02"
    data-component="Global-social_icons-Pinterest"
    aria-labelledby="pinterest-icon"
  >
    <title id="pinterest-icon">Pinterest</title>
    <path
      :fill="fillColor"
      d="M17,0A17,17,0,1,0,34,17,17,17,0,0,0,17,0Zm0,25.47a8.67,8.67,0,0,1-2.41-.35,7.26,7.26,0,0,0,.85-1.82c.11-.38.6-2.34.6-2.34A2.46,2.46,0,0,0,18.13,22c2.73,0,4.59-2.49,4.59-5.83a5.09,5.09,0,0,0-5.39-4.87c-4,0-6.07,2.9-6.07,5.31A3.27,3.27,0,0,0,13,19.88a.29.29,0,0,0,.42-.21L13.6,19a.44.44,0,0,0-.12-.48,2.42,2.42,0,0,1-.56-1.67,4,4,0,0,1,4.18-4.07c2.28,0,3.54,1.4,3.54,3.26,0,2.45-1.08,4.52-2.7,4.52a1.31,1.31,0,0,1-1.34-1.64,17.75,17.75,0,0,0,.75-3,1.14,1.14,0,0,0-1.14-1.28c-.91,0-1.64.94-1.64,2.2a3.33,3.33,0,0,0,.27,1.35s-.93,3.94-1.1,4.63a7.3,7.3,0,0,0-.14,1.95,8.49,8.49,0,1,1,3.41.72Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "Pinterest",
  props: {
    fillColor: {
      type: String,
      default: "",
    },
  },
};
</script>
