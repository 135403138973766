import { BREAKPOINTS } from "../util/constants";

export default {
  data() {
    return {
      windowWidth:
        (this.$store?.state?.header || this.$store?.state?.footer)
          ?.activeProperties?.isMobile === "1"
          ? BREAKPOINTS.extraSmall
          : BREAKPOINTS.large,
    };
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.handleResize();
    });
    this.handleResize();
  },
  computed: {
    isMobile() {
      return this.windowWidth < BREAKPOINTS.small;
    },
  },
  methods: {
    /**
     * @return {number} the width of the viewport in pixels
     */
    getScreenWidth() {
      return this.windowWidth;
    },
    /**
     * Update viewport size on window change.
     * Only switch to mobile content if it exists and the screen width is appropriate
     */
    handleResize() {
      this.windowWidth =
        typeof window !== "undefined"
          ? window.innerWidth ||
            document?.body?.clientWidth ||
            document?.documentElement?.clientWidth
          : BREAKPOINTS.large;
    },
    /**
     * @return {boolean} true if the viewport is narrower than full desktop
     */
    isMediumBreakPoint() {
      return this.getScreenWidth() <= BREAKPOINTS.medium;
    },
  },
};
