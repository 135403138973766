<template>
  <p
    data-style="copyright"
    v-if="copy_list.length === 1 || (isResponsive && !isCustomFooterEnabled)"
    class="copyright"
    data-component="Global-footer-Copyright"
  >
    © {{ new Date().getFullYear() }} Williams-Sonoma Inc., All Rights Reserved
  </p>
  <div v-else>
    <ul class="copyright">
      <li v-for="(copy, index) in copy_list" :key="index">
        <span class="copyright-info" v-if="!copy.link">{{
          copy.text ? copy.text : ""
        }}</span>
        <a v-else :href="copy.link ? copy.link : ''"
          >{{ copy.text ? copy.text : "" }}
          <span class="last-updated">{{
            copy.secondary_text ? copy.secondary_text : ""
          }}</span>
        </a>
        <span v-if="copy.link !== '' && index !== copy_list.length - 1">
          |
        </span>
      </li>
    </ul>
    <p
      class="copy-right-content"
      v-if="isCustomFooterEnabled && copyRights.copy_rights_content"
    >
      {{ copyRights.copy_rights_content }}
    </p>
  </div>
</template>

<script>
import { getConfigById } from "../../util/context";
import { logger } from "@js-ecom-mfe/logger";
import breakPoints from "../../mixins/breakPoints";
import EventBus from "../../util/event-bus";
import { REPONAME } from "../../util/constants";

const fileName = "Copyright.vue";

export default {
  name: "Copyright",
  mixins: [breakPoints],
  data() {
    return {
      isResponsive: false,
      copyRights: getConfigById("copyrights", this) ?? {},
      isCustomFooterEnabled:
        getConfigById("isCustomFooterEnabled", this) ?? false,
    };
  },
  beforeMount() {
    if (this.isMediumBreakPoint()) {
      this.isResponsive = true;
    }
  },
  mounted() {
    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
    });
  },
  methods: {
    getCopyList() {
      const copy_list = getConfigById("copyrights", this);
      if (!copy_list || !copy_list.copy_list) {
        logger.warn(
          REPONAME,
          fileName,
          "getCopyList",
          `Failed to fetch copyright.`
        );
        return [];
      }
      return copy_list.copy_list;
    },
  },
  created() {
    this.copy_list = this.getCopyList();
  },
};
</script>
