/* eslint-disable no-console */

import { logger } from "@js-ecom-mfe/logger";
import Cookies from "js-cookie";
import { LOYALTY_REWARDS_RECOGNITION_COOKIE, REPONAME } from "./constants";

const fileName = "context.js";

export const safeExistenceCheck = (valueString, scope) => {
  if (!valueString || typeof valueString !== "string") {
    return scope;
  }
  const keyArray = valueString.split("."),
    firstChild = scope[keyArray[0]];
  return firstChild
    ? safeExistenceCheck(keyArray.slice(1).join("."), firstChild)
    : firstChild;
};

export const getBrandConcept = (scope) => {
  const conceptCode =
    safeExistenceCheck("$store.state.header.concept", scope) ||
    safeExistenceCheck("$store.state.footer.concept", scope);
  if (!conceptCode) {
    logger.error(
      REPONAME,
      fileName,
      "getBrandConcept",
      "concept code is not properly set"
    );
  }
  return conceptCode || "XX";
};

const _getConfig = (scope) => {
  if (scope) {
    const headerConfig = safeExistenceCheck("$store.state.header", scope);
    const footerConfig = safeExistenceCheck("$store.state.footer", scope);

    if (headerConfig && headerConfig.config) {
      return headerConfig.config;
    } else if (footerConfig && footerConfig.config) {
      return footerConfig.config;
    }
  }

  throw new Error("Problem retrieving configuration from scope");
};

export const getSubBrand = (scope) => {
  const config = _getConfig(scope);
  const subs = (config.subBrands || {}).brands;
  if (!subs) {
    logger.error(
      REPONAME,
      fileName,
      "getSubBrand",
      `Error parsing subBrand config`
    );
    return "";
  }
  const headerSubBrand = safeExistenceCheck(
    "$store.state.header.subBrand",
    scope
  );
  const footerSubBrand = safeExistenceCheck(
    "$store.state.footer.subBrand",
    scope
  );
  const subBrandId = headerSubBrand ?? footerSubBrand;
  const defaultSub = subs.find((sub) => sub.default);
  const currentSub = subs.find((sub) => sub.id === subBrandId);

  return currentSub || defaultSub || "";
};

export const getConfigById = (id, scope, optional) => {
  const config = _getConfig(scope);
  const baseConfig = config[id];

  const subBrand = scope.subBrandContext ?? getSubBrand(scope).id;
  const subBrandConfig = safeExistenceCheck(
    `subBrandOverrides.${subBrand}.${id}`,
    config
  );

  if ((!subBrand || !subBrandConfig) && baseConfig) {
    return baseConfig;
  }

  if (!baseConfig) {
    // Only log warnings on client side
    if (typeof window !== "undefined") {
      window.missingConfigIDs = window.missingConfigIDs || [];

      // only log each missing ID once
      if (!~window.missingConfigIDs.indexOf(id)) {
        window.missingConfigIDs.push(id);
        (optional ? logger.debug : logger.warn)(
          REPONAME,
          fileName,
          "getConfigById",
          `unable to retrieve config with ID ${id}`
        );
      }
    }
    return null;
  }

  return { ...baseConfig, ...subBrandConfig };
};

/**
 * Returns sub brands of a brand
 */
export const getSubBrands = (scope) => {
  return (getConfigById("subBrands", scope) || {}).brands;
};

export const getApplicationUri = (scope) => {
  const applicationUri =
    safeExistenceCheck("$store.state.header.applicationUri", scope) ||
    safeExistenceCheck("$store.state.footer.applicationUri", scope);
  if (!applicationUri) {
    logger.error(
      REPONAME,
      fileName,
      "getApplicationUri",
      "applicationUri is not properly set"
    );
  }
  return applicationUri || "";
};

export const getPlacements = (scope) => {
  const placements = safeExistenceCheck(
    "$store.state.header.placements",
    scope
  );
  if (!placements) {
    logger.debug(REPONAME, fileName, "getPlacements", "placements are not set");
  }
  return placements || {};
};

export const getCatGenerationId = (scope) => {
  const genId =
    safeExistenceCheck("$store.state.header.catGenerationId", scope) ||
    safeExistenceCheck("$store.state.footer.catGenerationId", scope);
  if (!genId) {
    logger.error(
      REPONAME,
      fileName,
      "getCatGenerationId",
      "Unable to get category generation id"
    );
  }
  return genId || "";
};

export const getGovernorState = (scope) => {
  const governorState = safeExistenceCheck(
    "$store.state.header.governorState",
    scope
  );
  if (!governorState) {
    logger.error(
      REPONAME,
      fileName,
      "getGovernorState",
      "Unable to get governor state"
    );
  }
  return governorState || {};
};

export const getMyStoreState = (scope) => {
  const myStoreState = safeExistenceCheck("$store.state.header.myStore", scope);
  if (!myStoreState) {
    logger.error(
      REPONAME,
      fileName,
      "getMyStoreState",
      "Unable to get myStore state"
    );
  }
  return myStoreState || {};
};

export const getKeyRewardsLink = (link) => {
  const queryParams = `${link.link.split("?")[1] || ""}`;
  const queryParamString = `${queryParams ? `?${queryParams}` : ""}`;
  if (Cookies.get(LOYALTY_REWARDS_RECOGNITION_COOKIE) !== "true") {
    link.link = `/pages/the-key-rewards${queryParamString}`;
  } else {
    link.link = `/account/keyrewards.html${queryParamString}`;
  }
  return link.link;
};
