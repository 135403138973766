<template>
  <div data-style="email-sign-up" id="footer-email-sign-up">
    <h3
      v-if="form_details.sign_up_copy"
      class="footer-email-sign-up-copy-header"
      data-component="Global-footer-EmailSignUp"
    >
      {{ form_details.sign_up_copy_header }}
    </h3>
    <p
      v-if="form_details.sign_up_copy"
      class="footer-email-sign-up-text"
      data-component="Global-footer-EmailSignUp"
    >
      {{ form_details.sign_up_copy }}
    </p>
    <a
      v-if="form_details.sign_up_link"
      :href="`${
        $store.state.footer.activeProperties.isMobile === '1' ? '/m' : ''
      }/customer-service/email-list/`"
      class="signup-cta"
      >Sign Up Now</a
    >
    <form v-if="form_details.sign_up" id="join-email-footer">
      <label
        v-if="form_details.sign_up_copy"
        for="footer-email-signup"
        class="visually-hidden"
        >{{ form_details.sign_up_copy }}</label
      >
      <div class="footer-signup">
        <input
          type="email"
          name="emailId"
          id="footer-email-signup"
          :placeholder="form_details.placeholder"
          v-model="emailId"
          required
        />
        <button
          v-if="form_details.submit_button"
          type="submit"
          value="Submit"
          class="submit"
          @click.prevent="emailSubmit()"
          data-test-id="submit-button"
        ></button>
        <input
          v-else
          type="image"
          :src="form_details.icon ? form_details.icon : ''"
          alt="Submit email address"
          @click.prevent="emailSubmit()"
        />
      </div>
      <MarketingNotification
        v-if="subBrand === 'WE'"
        :isEmailSignUpEnabled="form_details.isWeKidsEmailSignupEnabledOnWe"
        :isDefaultEmailOptInSelected="form_details.isWeKidsDefaultSelected"
        :marketingNotificationText="form_details.weKidsNotificationText"
        :emailSignUp="'signUpDefault'"
        :flowType="'default'"
      />
      <MarketingNotification
        v-else-if="subBrand === 'WEK'"
        :isEmailSignUpEnabled="form_details.isWeEmailSignupEnabledOnWeKids"
        :isDefaultEmailOptInSelected="form_details.isWeDefaultSelected"
        :marketingNotificationText="form_details.weNotificationText"
        :emailSignUp="'signUpModernBabyKids'"
        :flowType="'modern-baby-kids'"
      />
    </form>
  </div>
</template>

<script>
import { logger } from "@js-ecom-mfe/logger";
import { REPONAME } from "../../util/constants";
import {
  getConfigById,
  getSubBrand,
  getBrandConcept,
} from "../../util/context";
import MarketingNotification from "../MarketingNotification.vue";

const fileName = "EmailSignUp.vue";
export default {
  name: "emailSignUp",
  components: {
    MarketingNotification,
  },
  data() {
    return {
      emailId: "",
      subBrand: getSubBrand(this).id,
      brand: getBrandConcept(this).toUpperCase(),
    };
  },
  methods: {
    getEmailSignupFormDetails() {
      const config = getConfigById("emailSignup", this);

      if (!config) {
        logger.warn(
          REPONAME,
          fileName,
          "getEmailSignupFormDetails",
          `Config error in EmailSignUp`
        );
        return [];
      }

      return config;
    },
    emailSubmit() {
      const email = this.emailId;
      const formElement = document.getElementById("join-email-footer");
      let emailOptIn = formElement.getElementsByClassName("email-opt-in")[0];
      let flowType = formElement.getElementsByClassName("flow-type")[0];
      let isEmailOptIn = false;
      let flowTypeValue = "";
      if (emailOptIn) {
        isEmailOptIn = emailOptIn.checked;
      }
      if (flowType) {
        flowTypeValue = flowType.value;
      }
      if (
        this.brand === "WE" &&
        emailOptIn &&
        flowType &&
        (this.form_details.isWeEmailSignupEnabledOnWeKids ||
          this.form_details.isWeKidsEmailSignupEnabledOnWe) &&
        window.WSI &&
        window.WSI.state
      ) {
        WSI.state.change("footerEmailSignUp", {
          email,
          isEmailOptIn,
          flowTypeValue,
        });
      } else if (window.WSI && window.WSI.state) {
        WSI.state.change("emailSignUp", {
          email,
        });
      }
    },
  },
  created() {
    this.form_details = this.getEmailSignupFormDetails();
  },
};
</script>
