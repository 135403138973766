import {
  browserSessionStorageAvailable,
  getJsonItemFromSessionStorage,
  saveJsonItemToSessionStorage,
} from "@js-ecom-mfe/browser-storage";
import { logger } from "@js-ecom-mfe/logger";
import Cookies from "js-cookie";
import Vue from "vue";
import { getClosestStore } from "../../services/closestStore";
import { getCurrencyData } from "../../services/countryDetails";
import { fetchEcmContentData } from "../../services/ecm";
import {
  CURRENT_ZIP_CODE_INFO,
  EMAIL_OVERLAY_SUPPRESSION_DURATION_IN_DAYS,
  MOBILE_EMAIL_OVERLAY_V2_VERSION,
  REPONAME,
  SESSION_STORAGE_CACHE_TTL,
  STORE_LIST_SESSION_STORAGE_NAME,
  STORE_LOCATION_KEY,
  USER_LOCATION_KEY,
  US_COUNTRY_CODE,
  US_CURRENCY_CODE,
  US_MARKET_CODE,
} from "../../util/constants";
import {
  getValidPostalCode,
  isPostalCodeUpdateRequired,
} from "../../util/postalCode";
import { getLocation } from "../../util/location";

const fileName = "header.js";

/**
 * Checks if object is null, or is an empty object.
 * @param {Object} obj Object to check
 * @returns {Boolean} True if object is null or is an empty object
 */
const isNullOrEmptyObject = (obj) => {
  return (
    obj === null || (typeof obj === "object" && Object.keys(obj).length === 0)
  );
};

export default {
  state: () => ({
    cartQuantity: 0,
    concept: "",
    ecmPromos: [],
    favoritedRecommendationItem: null,
    applicationUri: "",
    activeTargetTests: {},
    governorState: {},
    myStore: {
      storeZipCodeInfo: "",
      currentZipCodeInfo: "",
      isFlyoutOpen: false,
      selectedStore: null,
      storeList: [],
    },
    postalCodeFromLatLng: "",
    placements: {},
    shippingCountry: {
      countryCode: "",
    },
    activeTests: {},
    pznTriggeredCampaign: {
      id: "",
      name: "",
      products: [],
      hasFetched: false,
      hasFetchedWithUserToken: false,
    },
    currencyData: {
      selectedCountry: US_COUNTRY_CODE,
      selectedCurrency: US_CURRENCY_CODE,
      exchangeRate: 1,
      factor: 1,
    },
    market: "",
    tabsData: {},
    topLevelTabs: "",
    ssrEcmContentData: {},
    displaySignInPrompt: false,
  }),
  getters: {
    getActiveTargetTests: (state) => {
      return state.activeTargetTests;
    },
    getActiveTestById: (state) => (id) => {
      return state.activeTests[id] || null;
    },
    getCartQuantity: (state) => {
      return state.cartQuantity;
    },
    getConcept: (state) => {
      return state.concept;
    },
    getEcmPromos: (state) => {
      return state.ecmPromos;
    },
    getMyStoreIsFlyoutOpen: (state) => {
      return state.myStore.isFlyoutOpen;
    },
    getPlacements: (state) => {
      return state.placements;
    },
    getMarketCode: (state) => {
      if (state?.market === "US") {
        state.market = US_MARKET_CODE;
      }
      return state?.market || US_MARKET_CODE;
    },
    getMyStoreUserLocationString: (state) => {
      return state.myStore[USER_LOCATION_KEY];
    },
    getMyStoreUserLocation:
      (state) =>
      (id = "") => {
        const userLocation =
          state?.myStore?.[USER_LOCATION_KEY] !== ""
            ? state.myStore[USER_LOCATION_KEY]
            : state.governorState?.[USER_LOCATION_KEY];
        if (!userLocation) {
          return id ? null : {};
        }
        const locationInfo = userLocation.split("|");
        if (
          isPostalCodeUpdateRequired(locationInfo[2], state.config?.myStore) &&
          state.postalCodeFromLatLng
        ) {
          locationInfo[2] = state.postalCodeFromLatLng;
        }
        return getLocation(locationInfo, id);
      },
    getPznTriggeredCampaign: (state) => {
      return state.pznTriggeredCampaign;
    },
    getStoreLocationString: (state) => {
      return state.myStore[STORE_LOCATION_KEY] ?? "";
    },
    getStoreLocation:
      (state) =>
      (id = "") => {
        const userLocation = state.governorState[STORE_LOCATION_KEY];
        if (!userLocation) {
          return {};
        }
        const locationInfo = state.governorState[STORE_LOCATION_KEY].split("|");
        if (
          isPostalCodeUpdateRequired(
            locationInfo[2],
            state.config?.globalLocationDisplay
          ) &&
          state.postalCodeFromLatLng
        ) {
          locationInfo[2] = state.postalCodeFromLatLng;
        }
        return getLocation(locationInfo, id);
      },
    getSuppressionCookie: (state, getters) => {
      const selectedCampaign = getters.getSelectedCampaign;
      const suppressionCookie =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.campaigns[selectedCampaign]?.suppressionCookie;
      return suppressionCookie || "";
    },
    getSuppressionDurationInDays: (state, getters) => {
      const selectedCampaign = getters.getSelectedCampaign;
      const suppressionDurationInDays =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ].campaigns[selectedCampaign]?.suppressionDurationInDays;
      return (
        suppressionDurationInDays || EMAIL_OVERLAY_SUPPRESSION_DURATION_IN_DAYS
      );
    },
    getSelectedCampaign: (state) => {
      const selectedCampaign =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.selectedCampaign;
      return selectedCampaign || "";
    },
    getShowNoThanks: (state) => {
      const showNoThanks =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.show_no_thanks;
      return showNoThanks || "";
    },
    getEmailLabel: (state) => {
      const emailLabel =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.labels?.email;
      return emailLabel || "Email address";
    },
    getPhoneNoLabel: (state) => {
      const phoneLabel =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.labels?.phone;
      return phoneLabel || "Phone number (optional)";
    },
    getEmailSmsConfirmationText: (state) => {
      const emailSmsConfirmationText =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.messages?.emailSmsConfirmationText;
      return (
        emailSmsConfirmationText ||
        "Now you'll always be in the loop on all the latest trends, styles and collabs."
      );
    },
    getStartShoppingText: (state) => {
      const startShoppingText =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.labels?.startShoppingText;
      return startShoppingText || "START SHOPPING";
    },
    getEmailSmsErrorText: (state) => {
      const emailSmsErrorText =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.messages?.emailSmsErrorText;
      return emailSmsErrorText || "Please try again later.";
    },
    getThanksForSingingUpText: (state) => {
      const thanksForSigningUpText =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.messages?.thanksForSigningUpText;
      return thanksForSigningUpText || "Thanks for signing up!";
    },
    getPhoneNoRegex: (state) => {
      const phoneNoRegexp =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.regexp?.phoneNoRegexp;
      return phoneNoRegexp || "(\\d{0,3}|)(\\d{0,3}|)(\\d{0,4})";
    },
    getNoThanksLabel: (state) => {
      const NoThanksLabel =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.no_thanks_label;
      return NoThanksLabel || "No thanks";
    },
    getSignUpNowText: (state) => {
      const signUpNowText =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.labels?.signUpNowText;
      return signUpNowText || "SIGN UP NOW";
    },
    getInvalidEmailText: (state) => {
      const invalidEmailText =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.invalidEmailText;
      return invalidEmailText || "Please enter a valid email address.";
    },
    getEmailRequiredText: (state) => {
      const emailRequiredText =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.emailRequiredText;
      return emailRequiredText || "Email required";
    },
    getInvalidPhoneNoText: (state) => {
      const invalidPhoneNoText =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.invalidPhoneNoText;
      return (
        invalidPhoneNoText || "Please enter a valid 10-digit phone number."
      );
    },
    getHeaderErrorText: (state) => {
      const headerErrorText =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.headerErrorText;
      return headerErrorText || "Server Error";
    },
    getPostalCodeOptimizationEnabled: (state) => {
      return state.config?.postalCodeOptimization?.enabled ?? false;
    },
    isPhoneNumberFieldEnabled: (state) => {
      let isPhoneNumberEnabled =
        state.config?.marketingOverlay?.mobile?.version[
          MOBILE_EMAIL_OVERLAY_V2_VERSION
        ]?.phoneNumberFieldEnabled;
      if (typeof isPhoneNumberEnabled === "undefined") {
        isPhoneNumberEnabled = true;
      }
      return isPhoneNumberEnabled;
    },
    getSsrEcmContentData: (state) => {
      return state.ssrEcmContentData;
    },
    getZipCodeConfigs: (state) => {
      return {
        bingMaps: state?.shop?.config?.bingMaps ?? {},
        googleMaps: state?.shop?.config?.googleMaps ?? {},
        maps: state?.shop?.config?.maps ?? {},
      };
    },
    shouldDisplaySignInPrompt: (state) => {
      return state.displaySignInPrompt;
    },
  },
  mutations: {
    shopCurrencyDataUpdate: (state, currencyData) => {
      state.currencyData.selectedCountry = currencyData.selectedCountry;
      state.currencyData.selectedCurrency = currencyData.selectedCurrency;
      state.currencyData.exchangeRate = currencyData.exchangeRate;
      state.currencyData.factor = currencyData.factor;
      state.currencyData.quoteId = currencyData.quoteId;
    },
    changeApplicationUri: (state, url) => {
      state.applicationUri = url;
    },
    ecmPromosUpdate: (state, promoData) => {
      state.ecmPromos = [...promoData];
    },
    myStoreIsFlyoutOpenUpdate: (state, value) => {
      state.myStore.isFlyoutOpen = value;
    },
    myStoreUpdate: (state, newMyStoreData) => {
      state.myStore = {
        ...state.myStore,
        ...newMyStoreData,
      };
    },
    placementsUpdate: (state, placements) => {
      placements.forEach((placement) => {
        Vue.set(state.placements, placement.id, placement);
      });
    },
    removeCatDataFromTopNavItem: (state, topNavItems) => {
      state.config.primaryNavBar.topNavItems = topNavItems.map((item) => {
        if (item.catData) {
          delete item.catData;
        }
        return item;
      });
    },
    removeCatDataFromPrimaryNavBar: (state) => {
      delete state.config.primaryNavBar.catData;
    },
    removeCatDataFromSubBrand: (state) => {
      state.config?.subBrandOverrides[
        state.subBrand
      ]?.primaryNavBar?.topNavItems?.map((item) => {
        if (item.catData) {
          delete item.catData;
        }
        return item;
      });
    },
    removeCatDataFromSubBrandPrimaryNavBar: (state) => {
      if (
        state.config?.subBrandOverrides[state.subBrand]?.primaryNavBar?.catData
      ) {
        delete state.config?.subBrandOverrides[state.subBrand]?.primaryNavBar
          ?.catData;
      }
    },
    setCartQuantityMutation(state, value) {
      state.cartQuantity = value;
    },
    setDisplaySignInPrompt(state, value) {
      state.displaySignInPrompt = value;
    },
    setGovernorState: (state, newGovernorState) => {
      state.governorState = Object.assign(
        {},
        state.governorState,
        newGovernorState
      );
    },
    shippingCountryUpdate: (state, countryCode) => {
      Vue.set(state.shippingCountry, "countryCode", countryCode);
    },
    updateActiveTargetTests: (state, testConfig) => {
      state.activeTargetTests = { ...state.activeTargetTests, ...testConfig };
    },
    updateFavoritedRecommendationItem: (state, payload) => {
      state.favoritedRecommendationItem = payload;
    },
    urlUpdate: (state, url) => {
      state.url = url;
    },
    updatePznTriggeredCampaign: (state, campaign) => {
      state.pznTriggeredCampaign = {
        ...state.pznTriggeredCampaign,
        ...campaign,
      };
    },
    updateTabsData: (state, tabsData) => {
      state.tabsData = { ...tabsData };
    },
    updateTopLevelTabs: (state, updateTopLevelTabs) => {
      state.topLevelTabs = updateTopLevelTabs;
    },
    /**
     * Update the state for Postal code fetched from lat lng
     *
     * @param {Object} state the state object for Vuex
     * @param {String} newPostalCode - updated postal code fetched from API using lat lng
     */
    setPostalCodeFromLatLng: (state, newPostalCode) => {
      state.postalCodeFromLatLng = newPostalCode;
    },
    ssrEcmContentData: (state, ssrEcmContentData = {}) => {
      state.ssrEcmContentData = {
        ...state.ssrEcmContentData,
        ...ssrEcmContentData,
      };
    },
  },
  actions: {
    currencyDataFetch: async ({ commit, state }, options) => {
      const { selectedCountry, selectedCurrency } = options;
      let currencyData = {};
      const defaultCurrencyData = {
        selectedCountry: US_COUNTRY_CODE,
        selectedCurrency: US_CURRENCY_CODE,
        factor: 1,
        exchangeRate: 1,
        quoteId: 1,
      };

      if (
        selectedCountry === US_COUNTRY_CODE &&
        selectedCurrency === US_CURRENCY_CODE
      ) {
        // No fetch required if US & USD is selected
        currencyData = defaultCurrencyData;
        commit("shopCurrencyDataUpdate", currencyData);
      } else if (
        state.selectedCountry !== selectedCountry &&
        state.selectedCurrency !== selectedCurrency
      ) {
        try {
          let response;
          response = await getCurrencyData(
            state.applicationUri,
            selectedCountry,
            selectedCurrency
          );

          if (
            response?.data?.internationalCurrencyInfo?.factor &&
            response?.data?.internationalCurrencyInfo?.exchangeRate
          ) {
            const currencyInfo = response.data.internationalCurrencyInfo;
            currencyData = {
              selectedCountry: selectedCountry,
              selectedCurrency: selectedCurrency,
              factor: parseInt(currencyInfo.factor),
              exchangeRate: parseFloat(currencyInfo.exchangeRate),
              quoteId: currencyInfo.quoteId,
            };
          } else {
            // Default to US/USD in case of failure
            currencyData = defaultCurrencyData;
          }
          commit("shopCurrencyDataUpdate", currencyData);
        } catch (error) {
          logger.error(REPONAME, fileName, "currencyDataFetch", error);
        }
      }

      return `${state.currencyData?.selectedCountry}-${state.currencyData?.selectedCurrency}-${state.currencyData?.quoteId}`;
    },

    /**
     * Fetch nearby stores to display in the store list for the My Store component.
     * @param {object} param0 -- The standard commit, state object hook for Vuex
     */
    myStoreStoreListFetch: async ({ commit, state, getters }) => {
      let res;
      let storeList = [];
      let stores = [];

      const isPostalCodeOptimizationEnabled =
        state.config?.postalCodeOptimization?.enabled || false;

      // FUTURE: Store these as props in myStore so we don't have to keep doing this string manipulation
      let locationArr;
      if (isPostalCodeOptimizationEnabled) {
        locationArr = getters.getStoreLocation();
      } else {
        locationArr = state.myStore[USER_LOCATION_KEY].split("|");
      }

      //latitude is at the fourth position
      const lat = locationArr[3] ?? locationArr?.latitude;
      //longitude is at the fifth position
      const lng = locationArr[4] ?? locationArr?.longitude;
      if (browserSessionStorageAvailable()) {
        const currentSessionStoreInfo = getJsonItemFromSessionStorage(
          STORE_LIST_SESSION_STORAGE_NAME,
          {}
        );
        if (
          !isNullOrEmptyObject(currentSessionStoreInfo) &&
          currentSessionStoreInfo.lat === lat &&
          currentSessionStoreInfo.lng === lng &&
          currentSessionStoreInfo.ttl > new Date().getTime()
        ) {
          stores = currentSessionStoreInfo.stores;
          res = {
            data: {
              storeListResponse: { stores: stores },
            },
          };
        }
      }

      if (!stores.length) {
        try {
          res = await getClosestStore(state.concept, lat, lng);
        } catch (e) {
          logger.error(REPONAME, fileName, "myStoreStoreListFetch", e);
          res = {};
        }

        if (
          res?.data?.storeListResponse?.stores &&
          browserSessionStorageAvailable()
        ) {
          /*
           * note this is different from the storePickup session storage object. That object
           * only gets updated when a user select a store from this list. This is the current
           * list of stores that the rest of the code uses. This will not change unless the postal
           * code is changed.
           */
          const sessionCacheTtl =
            state?.config?.myStore?.sessionCacheTtl ||
            SESSION_STORAGE_CACHE_TTL;
          stores = {
            ttl: new Date().getTime() + sessionCacheTtl,
            lat: lat,
            lng: lng,
            stores: res.data.storeListResponse.stores,
          };
          saveJsonItemToSessionStorage(STORE_LIST_SESSION_STORAGE_NAME, stores);
        }
      }

      storeList = res?.data?.storeListResponse?.stores
        .map((store) => store.properties)
        .sort((a, b) => {
          return parseFloat(a.DISTANCE) > parseFloat(b.DISTANCE) ? 1 : -1;
        });

      commit("myStoreUpdate", { storeList });
      return res;
    },
    removeCatData: ({ commit }, topNavItems) => {
      commit("removeCatDataFromTopNavItem", topNavItems);
      commit("removeCatDataFromPrimaryNavBar");
      commit("removeCatDataFromSubBrand");
      commit("removeCatDataFromSubBrandPrimaryNavBar");
    },
    setAdobeTargetTests: ({ commit }, testConfig) => {
      commit("updateActiveTargetTests", testConfig);
    },
    setFavoritedRecommendationItem({ commit }, favoritedRecommendationItem) {
      commit("updateFavoritedRecommendationItem", favoritedRecommendationItem);
    },
    setPznTriggeredCampaign: ({ commit }, campaign) => {
      commit("updatePznTriggeredCampaign", campaign);
    },
    setCartQuantity: ({ commit }, value) => {
      commit("setCartQuantityMutation", value);
    },
    setMyStoreIsFlyoutOpen: ({ commit }, value) => {
      commit("myStoreIsFlyoutOpenUpdate", value);
    },
    setTabsData: ({ commit }, value) => {
      commit("updateTabsData", value);
    },
    setTopLevelTabs: ({ commit }, value) => {
      commit("updateTopLevelTabs", value);
    },
    /**
     * Sets up the state dependencies for CAN postal code
     * Only on initial load the postal code is considered from WSGEO cookie or else its taken from WSI governor state
     * Only if the postal is invalid - we execute the reverse lookup and fetch a new postal code from lat and lng
     * calls mutator setPostalCodeFromLatLng to set state with updated postal code fetched from lat and lng
     *
     * @param {Object} param0 - The standard commit, state object from Vuex
     * @param {Object} myStoreConfig - configs
     */
    setPostalCodeData: async ({ state, commit }, myStoreConfig = {}) => {
      let userLocation = "";
      if (!state?.postalCodeFromLatLng) {
        userLocation = Cookies.get(CURRENT_ZIP_CODE_INFO)?.split("|");
      } else {
        userLocation = state.governorState[USER_LOCATION_KEY].split("|");
      }
      if (
        userLocation &&
        userLocation[3] !== "undefined" &&
        userLocation[4] !== "undefined" &&
        isPostalCodeUpdateRequired(userLocation[2], myStoreConfig)
      ) {
        const newPostalCode = await getValidPostalCode(
          userLocation[3],
          userLocation[4],
          state.config
        );
        commit("setPostalCodeFromLatLng", newPostalCode);
      }
    },
    ecmDataFetch: async (store, data) => {
      try {
        const ecmData = await fetchEcmContentData(data);
        store.commit("ssrEcmContentData", ecmData);
      } catch (error) {
        logger.error(REPONAME, fileName, "ecmDataFetch", error);
      }
    },
  },
};
