/* eslint-disable no-underscore-dangle */
//TODO: review this file with @ssanders to ensure this is correct (currently it is just copied from the header one with minor changes)

import "./polyfill";
import { createApp } from "./app-footer";

const { app, store } = createApp();

window.addEventListener("load", () => {
  if (window.__FOOTER__INITIAL__STATE__) {
    store.replaceState(window.__FOOTER__INITIAL__STATE__);
  }

  app.$mount("footer[data-component='Global-Footer']");
});

if (process.env.NODE_ENV !== "production") {
  // App served from port 4000
  // Webpack dev server on port 9000
  window.VUE_DEVTOOLS_CONFIG = {
    openInEditorHost: "https://localhost:9998/",
  };
}

/* eslint-disable global-require */
if (module.hot) {
  const api = require("vue-hot-reload-api");
  const Vue = require("vue");

  api.install(Vue);
  if (!api.compatible) {
    throw new Error(
      "vue-hot-reload-api is not compatible with the version of Vue you are using."
    );
  }

  module.hot.accept();
}
