<template>
  <footer
    :class="{ 'atc-sticky-visible': isATCStikyVisible }"
    data-style="footer"
    :id="subBrand"
    data-component="Global-Footer"
  >
    <FooterSections />
    <template v-if="!isCustomFooterEnabled">
      <Copyright />
    </template>
  </footer>
</template>

<script>
import FooterSections from "./components/footer/FooterSections.vue";
import Copyright from "./components/footer/Copyright.vue";
import breakPoints from "./mixins/breakPoints";
import resize from "./mixins/resize";
import { getSubBrand, getConfigById } from "./util/context";

export default {
  name: "footer-app-global",
  mixins: [breakPoints, resize],
  components: {
    FooterSections,
    Copyright,
  },
  data() {
    const subBrand = getSubBrand(this).id;
    const isCustomFooterEnabled =
      getConfigById("isCustomFooterEnabled", this) ?? false;
    return {
      subBrand,
      isCustomFooterEnabled,
      isATCStikyVisible: false,
    };
  },
  mounted() {
    // handle responsive footer at medium breakpoint
    if (this.isMediumBreakPoint()) {
      this.handleResponsiveState();
    }
    // Listen event "onATCStickyShow" WSI State Change
    // when ATCSticky on mobile is visible. update the state of isATCStikyVisible
    WSI.state.onChange("onATCStickyShow", (event) => {
      this.isATCStikyVisible = event;
    });
  },
};
</script>
