<template>
  <span
    data-style="feedback"
    class="footer-feedback"
    data-component="Global-footer-Feedback"
  >
    <!-- WS -->
    <span v-if="brand_concept === 'WS'">
      <input
        type="hidden"
        name="sessionCookieName"
        id="sessionCookieName"
        value="WSPE_SID"
      />
      <a id="oo_feedback" href="javascript:O_LC('');" class="feedback-link">
        Site Feedback
      </a>
    </span>

    <!-- MG -->
    <span v-else-if="brand_concept === 'MG'">
      <input
        type="hidden"
        name="sessionCookieName"
        id="sessionCookieName"
        value="MGPE_SID"
      />
      <a href="javascript:O_LC('');" aria-describedby="external-notification">
        Give Us Feedback
      </a>
    </span>

    <!-- PB -->
    <span v-else-if="brand_concept === 'PB'">
      <a href="javascript:O_LC('');" aria-describedby="external-notification">
        Give Us Feedback
      </a>
    </span>

    <!-- WE -->
    <span v-else-if="brand_concept === 'WE'">
      <input
        type="hidden"
        name="sessionCookieName"
        id="sessionCookieName"
        value="WEPE_SID"
      />
      <a
        class="footer-link__title-link feedback-link"
        href="javascript:O_LC('');"
        onclick="oOobj2.OnClick()"
        aria-describedby="op-external-notification"
      >
        <svg
          width="13px"
          height="13px"
          viewBox="0 0 25 25"
          role="img"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          class="svg-image"
        >
          <title>Customer Feedback</title>
          <desc>Tell us what you think.</desc>
          <defs></defs>
          <g
            class="we-icon__customer-service-feedback-outer"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              class="we-icon__customer-service-feedback"
              transform="translate(-7.000000, -8.000000)"
              fill="#363636"
            >
              <g
                transform="translate(7.500000, 7.500000)"
                class="we-icon__customer-service-feedback-inner"
              >
                <g>
                  <path
                    d="M21.6815294,24.561708 L12.3531521,24.561708 C8.74274043,24.5603409 5.32816845,22.9200178 3.07061511,20.1024786 C0.813061782,17.2849393 -0.0433358984,13.594879 0.74249223,10.0710248 C1.95469167,4.03243023 7.58006895,-0.0789613925 13.7016333,0.599641583 C19.8231976,1.27824456 24.411529,6.52187178 24.271739,12.6793478 C24.2139061,14.6022414 23.6517282,16.4761678 22.6415372,18.113354 C21.8642065,19.3111373 22.0303362,20.8893696 23.040031,21.899045 C23.4350432,22.4041242 23.5013047,23.0924416 23.2098976,23.6635996 C22.9184904,24.2347577 22.3223046,24.5850917 21.6815294,24.561708 Z M2.53571427,10.5057453 C1.8869286,13.5100038 2.63275824,16.6464838 4.56440992,19.037135 C6.47679416,21.3947681 9.35366547,22.7599501 12.3893788,22.7503726 L21.4460558,22.7503726 C20.116103,21.1367739 20.0199326,18.836081 21.2105822,17.1171195 C21.9955604,15.7672679 22.425559,14.2404615 22.4604036,12.6793478 C22.4645029,9.64997685 21.0998539,6.78088552 18.7471661,4.87249222 C16.3565148,2.94084054 13.2200348,2.1950109 10.2157764,2.84379657 C6.37459417,3.67044898 3.37143445,6.66652578 2.53571427,10.5057453 Z"
                    fill-rule="nonzero"
                    class="we-icon__customer-service-feedback-path"
                  ></path>
                  <polygon
                    fill-rule="nonzero"
                    points="13.2044798 8.15100929 11.5199378 8.15100929 11.5199378 11.8280201 7.82481364 11.8280201 7.82481364 13.5306754 11.5199378 13.5306754 11.5199378 17.2076863 13.2044798 17.2076863 13.2044798 13.5306754 16.8814906 13.5306754 16.8814906 11.8280201 13.2044798 11.8280201"
                    class="we-icon__customer-service-feedback-polygon"
                  ></polygon>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <span>Give Us Feedback</span>
      </a>
    </span>
  </span>
</template>

<script>
import { getBrandConcept } from "../../util/context";
export default {
  name: "Feedback",
  methods: {
    getBrand() {
      const brand = getBrandConcept(this);
      return brand;
    },
  },
  created() {
    this.brand_concept = this.getBrand();
  },
};
</script>
