/**
 * focusTrap.js is a mixin that includes a set of methods to ease adding keyboard navigation to modals, drawers, and flyouts
 * Trapping focus using focusableElements and checkKeyDown
 * Returning focus to trigger element on close using setFocusTriggerEl and restoreFocusToTrigger
 * Adding/removing aria-hidden to page elements using preventBackgroundFocus and restoreBackgroundFocus
 */
export default {
  data() {
    return {
      closerFunction: () => {
        return;
      },
      focusableElements: [],
      focusOrigin: "",
    };
  },
  methods: {
    /**
     * Maintains focus in the modal/drawer/flyout, prevents focusing main page when modal is open.
     * @param {Event} event the keydown event from the listener
     */
    checkKeyDown(event) {
      /**
       * check tab/shift tab key press
       * close modal and return early if escape
       */
      if (event.key === "Esc" || event.key === "Escape") {
        this.closerFunction();
        return;
      }
      // escape early if only 1 or no elements to focus
      if (this.focusableElements.length < 2 && event.key === "Tab") {
        return;
      }
      const last = this.focusableElements.length - 1;
      if (
        event.key === "Tab" &&
        event.shiftKey === false &&
        event.target === this.focusableElements[last]
      ) {
        event.preventDefault();
        this.focusableElements[0].focus();
      } else if (
        event.key === "Tab" &&
        event.shiftKey === true &&
        event.target === this.focusableElements[0]
      ) {
        event.preventDefault();
        this.focusableElements[last].focus();
      }
      return;
    },
    /**
     * Get all focusable elements in a given element
     * This is to be called on modal/flyout open and/or update
     * @param {Element} wrapper is the wrapper element to search to get all focusable children(i.e. the modal or flyout element)
     */
    getFocusableElements(wrapper) {
      //get all elements
      this.focusableElements = wrapper?.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
      );
    },
    /**
     * Removes background elements from accessibility tree.
     * This is to be called on modal/flyout open and/or update
     * @param {*} el element to add aria-hidden
     */
    preventBackgroundFocus(el) {
      el.setAttribute("aria-hidden", true);
    },
    /**
     * Restores previously hidden element back to accessibility tree.
     * This is to be called on modal/flyout close or destroy
     * @param {*} el element to remove aria-hidden
     */
    restoreBackgroundFocus(el) {
      el.removeAttribute("aria-hidden");
    },
    /**
     * Restores focus to trigger element.
     * This is to be called on modal/flyout close or destroy
     */
    restoreFocusToTrigger() {
      this.focusOrigin && this.focusOrigin.focus();
    },
    /**
     * Sets the closer function to be called when escape is pressed
     * @params {Function} fn function used to close the modal/drawer/flyout
     */
    setCloserFunction(fn) {
      this.closerFunction = fn;
    },
    /**
     * Sets focusOrigin element for restoring focus on close.
     * This is to be called on modal/flyout open and/or update
     * @param {Element} el element that triggered modal/drawer/flyout open
     */
    setFocusTriggerEl(el) {
      this.focusOrigin = el;
    },
  },
};
