<template>
  <ul data-style="mobile-footer-links" class="mobile-footer-links">
    <li v-for="(link, index) in footerLinksMobile" :key="index">
      <a
        v-if="link.text.includes('Key Rewards')"
        :href="updateKeyRewardsLink(link)"
        >{{ link.text }}</a
      >
      <a v-else :href="link.link">{{ link.text }}</a>
    </li>
  </ul>
</template>

<script>
import { getKeyRewardsLink } from "../../../src/util/context";
export default {
  props: {
    footerLinksMobile: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    updateKeyRewardsLink(link) {
      return getKeyRewardsLink(link);
    },
  },
};
</script>
