<template>
  <svg
    data-style="instagram"
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.02 34.02"
    data-component="Global-social_icons-Instagram"
    aria-labelledby="instagram-icon"
  >
    <title id="instagram-icon">Instagram</title>
    <path
      :fill="fillColor"
      d="M17,13.94A3,3,0,0,0,13.9,17a2.78,2.78,0,0,0,.89,2.13,3.1,3.1,0,0,0,4.35,0A2.92,2.92,0,0,0,20,17a3,3,0,0,0-.89-2.18A3,3,0,0,0,17,13.94Z"
    ></path>
    <path
      :fill="fillColor"
      d="M24.34,12.49a3.63,3.63,0,0,0-.21-.85,4.21,4.21,0,0,0-.68-1.07,3,3,0,0,0-1.07-.68,3.58,3.58,0,0,0-.85-.22l-1.24-.12c-.47,0-.85-.05-1.15-.05H14.88a10.42,10.42,0,0,0-1.16.05,5.57,5.57,0,0,0-1.23.12,3.58,3.58,0,0,0-.85.22,4,4,0,0,0-1.07.68,3,3,0,0,0-.68,1.07,3.58,3.58,0,0,0-.22.85c0,.34-.08.72-.12,1.23s-.05.86-.05,1.16v4.26a10.25,10.25,0,0,0,.05,1.15,5.6,5.6,0,0,0,.12,1.24,7.8,7.8,0,0,0,.22.85,4.28,4.28,0,0,0,.68,1.07,3.16,3.16,0,0,0,1.07.68,3.63,3.63,0,0,0,.85.21c.34,0,.72.09,1.23.13s.86,0,1.16,0h4.26c.3,0,.68,0,1.15,0a5,5,0,0,0,1.24-.13,3.63,3.63,0,0,0,.85-.21,4.58,4.58,0,0,0,1.07-.68,3.16,3.16,0,0,0,.68-1.07,3.63,3.63,0,0,0,.21-.85c0-.34.09-.73.13-1.24s0-.85,0-1.15V14.88c0-.3,0-.69,0-1.16A5,5,0,0,0,24.34,12.49Zm-4.05,7.84A4.5,4.5,0,0,1,17,21.7a4.59,4.59,0,0,1-3.33-1.37A4.49,4.49,0,0,1,12.28,17a4.6,4.6,0,0,1,1.36-3.33A4.54,4.54,0,0,1,17,12.32a4.35,4.35,0,0,1,3.32,1.36,4.72,4.72,0,0,1,0,6.65Zm2.35-7.46a1.05,1.05,0,0,1-.77.34,1,1,0,0,1-.77-.34,1,1,0,0,1,0-1.53,1,1,0,0,1,.77-.34,1.05,1.05,0,0,1,.77.34,1,1,0,0,1,0,1.53Z"
    ></path>
    <path
      :fill="fillColor"
      d="M17,0A17,17,0,1,0,34,17,17,17,0,0,0,17,0Zm9.08,20.76A5.52,5.52,0,0,1,24.6,24.6a5.78,5.78,0,0,1-3.84,1.49c-.68,0-2,0-3.75,0s-3.07,0-3.75,0A5.48,5.48,0,0,1,9.42,24.6a5.61,5.61,0,0,1-1.49-3.84c0-.68,0-2,0-3.75s0-3.07,0-3.75A5.39,5.39,0,0,1,9.42,9.42a5.57,5.57,0,0,1,3.84-1.49c.68,0,2,0,3.75,0s3.07,0,3.75,0A5.43,5.43,0,0,1,24.6,9.42a5.76,5.76,0,0,1,1.49,3.84c0,.68,0,1.91,0,3.75S26.13,20.08,26.09,20.76Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "Instagram",
  props: {
    fillColor: {
      type: String,
      default: "",
    },
  },
};
</script>
