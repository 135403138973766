<template>
  <section
    data-style="footer-sections"
    data-component="Global-footer-FooterSections"
  >
    <template v-if="!isCustomFooterEnabled">
      <FooterMenu
        v-for="(footer_link, index) in footer_links"
        :key="index"
        :footer_link="footer_link"
      />
      <MobileFooterLinks
        v-if="isMobile() || isResponsive"
        :footer-links-mobile="footerLinksMobile"
      />
      <MobileEmailSignUp
        v-if="isResponsive"
        :emailSignUp="configMobileEmailSignUp"
      />
      <div class="social-icons">
        <div v-if="false" />
        <EmailSignUp v-if="!isResponsive" />
        <FinancialIncentives v-if="display_financial_incentives" />
        <CCPAButton class="HOM" />
        <BlogLink v-if="!isResponsive" />
        <div style="text-align: center">
          <AppStoreBadge v-if="display_mobile_app_badge" />
        </div>
        <div v-if="display_social_icons">
          <p class="social-icons">Follow Us</p>
          <SocialIcons />
        </div>
        <div class="footer-legal">
          <div class="text section">
            <p>
              {{ footer_legal.primary_text }}
              <a :href="footer_legal.link" target="_blank">
                {{ footer_legal.anchorText }}
              </a>
              {{ footer_legal.secondary_text }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="footer-wrapper">
        <div class="footer-logo">
          <img :src="getBrandLogo" :alt="brandLogoConfig.alt_text" />
        </div>
        <div class="footer-links-container">
          <div class="footer-links">
            <FooterMenu
              v-for="(footer_link, index) in footer_links"
              :key="index"
              :footer_link="footer_link"
            />
            <MobileEmailSignUp
              v-if="isResponsive"
              :emailSignUp="configMobileEmailSignUp"
            />
            <EmailSignUp v-if="!isResponsive" />
            <SocialIcons />
            <Copyright />
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import { getConfigById, getApplicationUri } from "../../util/context";
import SocialIcons from "../../components/footer/SocialIcons.vue";
import EmailSignUp from "../../components/footer/EmailSignUp.vue";
import BlogLink from "../../components/footer/BlogLink.vue";
import FinancialIncentives from "../../components/footer/FinancialIncentives.vue";
import CCPAButton from "../../components/header/CCPAHeaderButton.vue";
import FooterMenu from "../../components/footer/FooterMenu.vue";
import MobileFooterLinks from "../../components/footer/MobileFooterLinks.vue";
import MobileEmailSignUp from "../../components/footer/MobileEmailSignUp.vue";
import { logger } from "@js-ecom-mfe/logger";
import EventBus from "../../util/event-bus";
import { REPONAME, GLOBAL_NETSTORAGE_PATH } from "../../util/constants";
import Copyright from "./Copyright.vue";

const fileName = "FooterSections.vue";
export default {
  name: "FooterLinks",
  components: {
    SocialIcons,
    EmailSignUp,
    FinancialIncentives,
    FooterMenu,
    MobileFooterLinks,
    MobileEmailSignUp,
    BlogLink,
    CCPAButton,
    Copyright,
    AppStoreBadge: () =>
      import(
        /* webpackChunkName: "app-store-badge" */ "../../components/footer/AppStoreBadge.vue"
      ),
  },
  data() {
    const brandLogoConfig = getConfigById("brandLogo", this) ?? {};

    return {
      isResponsive: false,
      footer_legal: {},
      linksList: [],
      brandCreditCardName: getConfigById("creditCardServiceProviderName", this),
      isCustomFooterEnabled:
        getConfigById("isCustomFooterEnabled", this) ?? false,
      brandLogoConfig,
    };
  },
  methods: {
    assignFooterLinks() {
      this.footer_links =
        // eslint-disable-next-line eqeqeq
        this.brandCreditCardName != null
          ? this.getFooterLinksList().map((item) => {
              item.column.map((listItem) => {
                listItem.link = listItem.redirectUrls
                  ? listItem.redirectUrls[this.brandCreditCardName]
                  : listItem.link;
                return listItem;
              });
              return item;
            })
          : this.getFooterLinksList();
    },
    getFooterLinksList() {
      const footer_links_list = getConfigById("footerLinks", this);
      this.footer_legal = footer_links_list.footer_legal;
      if (
        !footer_links_list ||
        !footer_links_list.footer_links ||
        !footer_links_list.mobile_footer_menu
      ) {
        logger.warn(
          REPONAME,
          fileName,
          "getFooterLinksList",
          `Config error in footerLinks`
        );
        return [];
      }
      return this.isMobile() || this.isResponsive
        ? footer_links_list.mobile_footer_menu
        : footer_links_list.footer_links;
    },
    isMobile() {
      return this.$store.state.footer.activeProperties.isMobile === "1";
    },
    displaySocialIcons() {
      const config = getConfigById("socialIcons", this);
      if (!config) {
        logger.warn(
          REPONAME,
          fileName,
          "displaySocialIcons",
          `Config error in socialIcons`
        );
        return true;
      }
      return this.isMobile() || this.isResponsive
        ? config.mobile_display_enabled
        : true;
    },
    showFinancialIncentives() {
      const config = getConfigById("financialIncentives", this);
      if (!config) {
        logger.warn(
          REPONAME,
          fileName,
          "showFinancialIncentives",
          `Parsing error in config for financialIncentives`
        );
        return true;
      }
      return this.isMobile() || this.isResponsive
        ? config.mobile_display_enabled
        : true;
    },
    getMobileFooterLinks() {
      const footer_links_list = getConfigById("footerLinks", this);
      return this.isMobile() || this.isResponsive
        ? footer_links_list.mobile_footer_links
        : [];
    },
    getMobileEmailSignupFormDetails() {
      const config = getConfigById("mobileEmailSignUp", this) || {};
      return config;
    },
    displayAppStoreBadge() {
      const config = getConfigById("appStoreBadge", this);

      if (!config) {
        return false;
      }
      return this.isMobile() || this.isResponsive
        ? config.mobile_app_store_enabled
        : false;
    },
  },
  computed: {
    /**
     * get brand logo
     */
    getBrandLogo() {
      let brandLogo = this.brandLogoConfig?.img_link ?? "";
      if (this.brandLogoConfig?.loadFromNetStorage) {
        const netStoragePath =
          this.brandLogoConfig?.netStoragePath ?? GLOBAL_NETSTORAGE_PATH;
        const applicationUri = getApplicationUri(this) || "/";
        brandLogo = `${applicationUri}${netStoragePath}${this.brandLogoConfig.img_link}`;
      }
      return brandLogo;
    },
  },
  created() {
    this.assignFooterLinks();
    this.display_social_icons = this.displaySocialIcons();
    this.display_mobile_app_badge = this.displayAppStoreBadge();
    this.display_financial_incentives = this.showFinancialIncentives();
    // this is only for medium breakpoint
    this.footerLinksMobile = this.getMobileFooterLinks();
    this.configMobileEmailSignUp = this.getMobileEmailSignupFormDetails();
  },
  mounted() {
    EventBus.$on("mediumBreakPoint", () => {
      this.isResponsive = true;
      // since the data for medium screen and large screen are different
      // we have to get footer links according to view port
      this.display_social_icons = this.displaySocialIcons();
      this.display_financial_incentives = this.showFinancialIncentives();
      this.footerLinksMobile = this.getMobileFooterLinks();
      this.assignFooterLinks();
      this.configMobileEmailSignUp = this.getMobileEmailSignupFormDetails();
    });
    EventBus.$on("largeBreakPoint", () => {
      this.isResponsive = false;
      this.display_social_icons = this.displaySocialIcons();
      this.assignFooterLinks();
      this.display_financial_incentives = this.showFinancialIncentives();
    });
  },
};
</script>
