import EventBus from "../util/event-bus";
export default {
  methods: {
    /**
     * Gets called when window is resized and update responsive state wherever required
     */
    handleResponsiveState() {
      const isMediumBreakPoint = this.isMediumBreakPoint();
      const responsiveState = isMediumBreakPoint
        ? "mediumBreakPoint"
        : "largeBreakPoint";
      EventBus.$emit(responsiveState);
      if (!isMediumBreakPoint) {
        document.body.classList.remove("no-scroll");
      }
    },
  },
  mounted() {
    // run after the DOM is rendered
    this.$nextTick().then(() => {
      window.addEventListener("resize", this.handleResponsiveState);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResponsiveState);
  },
};
