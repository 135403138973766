<template>
  <svg
    data-style="youtube"
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.02 34.02"
    data-component="Global-social_icons-YouTube"
    aria-labelledby="youtube-icon"
  >
    <title id="youtube-icon">YouTube</title>
    <path
      :fill="fillColor"
      d="M18.82,19.16a.8.8,0,0,0-.36.09,1.39,1.39,0,0,0-.34.27V17.83h-.78v5.25h.78v-.3a1.19,1.19,0,0,0,.34.27.84.84,0,0,0,.4.08.64.64,0,0,0,.53-.22,1,1,0,0,0,.18-.63V20.13a1.12,1.12,0,0,0-.2-.72A.64.64,0,0,0,18.82,19.16Zm-.05,3a.38.38,0,0,1-.07.27.23.23,0,0,1-.21.09.41.41,0,0,1-.18-.05.54.54,0,0,1-.19-.13V19.93a.56.56,0,0,1,.16-.12.38.38,0,0,1,.17,0,.29.29,0,0,1,.24.11.42.42,0,0,1,.08.29Z"
    ></path>
    <polygon
      :fill="fillColor"
      points="11.52 18.59 12.42 18.59 12.42 23.08 13.3 23.08 13.3 18.59 14.21 18.59 14.21 17.83 11.52 17.83 11.52 18.59"
    ></polygon>
    <path
      :fill="fillColor"
      d="M16,22.15a.91.91,0,0,1-.24.2.42.42,0,0,1-.22.09.19.19,0,0,1-.15-.06.34.34,0,0,1,0-.2v-3h-.77v3.25a.8.8,0,0,0,.13.51.48.48,0,0,0,.41.18.85.85,0,0,0,.44-.13,1.23,1.23,0,0,0,.45-.36v.43h.78V19.2H16Z"
    ></path>
    <path
      :fill="fillColor"
      d="M16.89,14.86a.37.37,0,0,0,.28-.1.36.36,0,0,0,.1-.27V12.16a.26.26,0,0,0-.1-.22.45.45,0,0,0-.28-.09.36.36,0,0,0-.26.09.26.26,0,0,0-.1.22v2.33a.38.38,0,0,0,.09.27A.35.35,0,0,0,16.89,14.86Z"
    ></path>
    <path
      :fill="fillColor"
      d="M17,0A17,17,0,1,0,34,17,17,17,0,0,0,17,0Zm1.86,11.26h.87v3.27a.37.37,0,0,0,.06.23.24.24,0,0,0,.17.06.51.51,0,0,0,.25-.09,1.18,1.18,0,0,0,.27-.23V11.22h.87v4.27h-.87V15a1.79,1.79,0,0,1-.5.4,1.24,1.24,0,0,1-.51.13.55.55,0,0,1-.46-.19.94.94,0,0,1-.15-.58Zm-3.24,1a1,1,0,0,1,.35-.79,1.44,1.44,0,0,1,.95-.3,1.27,1.27,0,0,1,.89.31,1.07,1.07,0,0,1,.34.8v2.2a1.08,1.08,0,0,1-.34.86,1.48,1.48,0,0,1-1.85,0,1.13,1.13,0,0,1-.34-.86ZM13.25,9.7,13.89,12H14l.6-2.32h1l-1.14,3.39v2.4h-1V13.2L12.26,9.7ZM25.51,21.8a2.43,2.43,0,0,1-2.42,2.42H10.93A2.44,2.44,0,0,1,8.5,21.8V19.32a2.44,2.44,0,0,1,2.43-2.43H23.09a2.44,2.44,0,0,1,2.42,2.43Z"
    ></path>
    <path
      :fill="fillColor"
      d="M21.16,19.11a1.18,1.18,0,0,0-.85.31,1.09,1.09,0,0,0-.34.83V22a1.3,1.3,0,0,0,.3.88,1.08,1.08,0,0,0,.83.32,1.14,1.14,0,0,0,.86-.3,1.22,1.22,0,0,0,.3-.9v-.2h-.8V22a.82.82,0,0,1-.08.44.34.34,0,0,1-.27.1.29.29,0,0,1-.26-.12.86.86,0,0,1-.07-.42v-.73h1.48v-1a1.14,1.14,0,0,0-.29-.84A1.06,1.06,0,0,0,21.16,19.11Zm.3,1.52h-.68v-.39a.55.55,0,0,1,.08-.35.32.32,0,0,1,.26-.11.33.33,0,0,1,.26.11.62.62,0,0,1,.08.35Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "Youtube",
  props: {
    fillColor: {
      type: String,
      default: "",
    },
  },
};
</script>
