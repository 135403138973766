<template>
  <svg
    data-style="blog"
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 34.01 34.02"
    data-component="Global-social_icons-Blog"
    aria-labelledby="blog-icon"
  >
    <title id="blog-icon">Blog</title>
    <path
      :fill="fillColor"
      d="M17,0A17,17,0,1,0,34,17,17,17,0,0,0,17,0ZM12.36,24.83h0a2.26,2.26,0,0,1-3.19,0h0a2.22,2.22,0,0,1,0-3.18h0a2.25,2.25,0,1,1,3.19,3.18Zm4.1.68h0a8,8,0,0,0-8-8V14.29A11.25,11.25,0,0,1,19.73,25.51Zm5.8,0A13.79,13.79,0,0,0,8.51,11.75V8.5a17,17,0,0,1,17,17Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "Blog",
  props: {
    fillColor: {
      type: String,
      default: "",
    },
  },
};
</script>
