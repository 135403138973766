export const getLocation = (location, id) => {
  switch (id) {
    case "country":
      return location[0];
    case "state":
      return location[1];
    case "zipCode":
      return location[2];
    case "lat":
      return location[3];
    case "lng":
      return location[4];
    default:
      return {
        country: location[0],
        state: location[1],
        zipCode: location[2],
        latitude: location[3],
        longitude: location[4],
        value: location[2],
      };
  }
};
